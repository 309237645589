import React from 'react';
import PropTypes from 'prop-types';

import { Divider, IconButton, Stack, Typography } from '@mui/material';
import { Icon } from '@loggi/loggi-icons';
import { colors, spacing } from '@loggi/front-design-tokens';

import { Address } from 'models';

export default function AddressSection({
  title,
  address,
  subtitle,
  onClick,
  enableEdit
}) {
  return (
    <>
      <Stack
        direction="row"
        gap={spacing.inline.xsmall}
        p={spacing.inset.large}
        justifyContent="space-between"
      >
        <Stack gap={spacing.stack.xxxsmall}>
          <Typography
            variant="subtitleLarge"
            color={colors.neutrals['on-surface']}
          >
            {title}
          </Typography>
          <Typography
            variant="bodyTextMedium"
            color={colors.neutrals['on-surface']}
            data-testid="address-infos"
          >
            {address.structured.main}
            <br />
            {address.structured.secondary.replace(/,/g, ' • ')}
            <br />
            {address.complement && `${address.complement}`}
          </Typography>
          <Typography
            variant="bodyTextMedium"
            color={colors.neutrals['on-surface']}
          >
            {`${subtitle.main} ${subtitle.secondary || ''}`}
          </Typography>
        </Stack>
        {enableEdit && (
          <Stack justifyContent="center">
            <IconButton
              data-testid="address-section-button"
              variant="blank"
              size="small"
              color="secondary"
              onClick={onClick}
            >
              <Icon
                name="ActionEdit02"
                color={colors.interaction['on-secondary']}
                size="small"
              />
            </IconButton>
          </Stack>
        )}
      </Stack>

      <Divider color={colors.neutrals['outline-low']} />
    </>
  );
}

AddressSection.propTypes = {
  title: PropTypes.string.isRequired,
  address: PropTypes.instanceOf(Address).isRequired,
  subtitle: PropTypes.shape({
    main: PropTypes.string,
    secondary: PropTypes.string
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  enableEdit: PropTypes.bool.isRequired
};

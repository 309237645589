/* eslint-disable import/prefer-default-export */
import { NOT_INFORMED } from '../constants/packaging-service-type';

export const STATES = {
  IDLE: 'IDLE',
  BUDGET: 'BUDGET',
  HOME: 'HOME',
  WALLET: 'WALLET',
  PICKUP_ADDRESS: 'PICKUP_ADDRESS',
  DELIVERY_ADDRESS: 'DELIVERY_ADDRESS',
  POSTAL_CODE_ADDRESSES: 'POSTAL_CODE_ADDRESSES',
  ORIGIN_ADDRESS: 'ORIGIN_ADDRESS',
  DESTINATION_ADDRESS: 'DESTINATION_ADDRESS',
  PICKUP_SCHEDULE: 'PICKUP_SCHEDULE',
  REQUIRE_PACKAGING: 'REQUIRE_PACKAGING',
  NEW_PACKAGE_SIZE: 'NEW_PACKAGE_SIZE',
  PACKAGE_DIMENSIONS: 'PACKAGE_DIMENSIONS',
  ABOUT_PACKAGING: 'ABOUT_PACKAGING',
  DROPOFF_ONBOARDING: 'DROPOFF_ONBOARDING',
  PACKAGE_SIZE: 'PACKAGE_SIZE',
  INSURANCE: 'INSURANCE',
  LIST_AGENCIES: 'LIST_AGENCIES',
  ONBOARD_INDESPACHO: 'ONBOARD_INDESPACHO',
  PICKUP_CUSTOMER: 'PICKUP_CUSTOMER',
  DELIVERY_CUSTOMER: 'DELIVERY_CUSTOMER',
  SUMMARY: 'SUMMARY',
  SUMMARY_INDESPACHO: 'SUMMARY_INDESPACHO',
  SHIPMENT_CREATED: 'SHIPMENT_CREATED',
  SHIPMENT_CREATED_V2: 'SHIPMENT_CREATED_V2',
  SERVICES_INDESPACHO: 'SERVICES_INDESPACHO',
  SHIPMENT_CREATED_INDESPACHO: 'SHIPMENT_CREATED_INDESPACHO',
  COUPONS: 'COUPONS',
  PIX_PAYMENT: 'PIX_PAYMENT',
  PAYMENT: 'PAYMENT',
  PAYMENT_COUPONS: 'PAYMENT_COUPONS',
  ADD_WALLET_BALANCE_OPTIONS: 'ADD_WALLET_BALANCE_OPTIONS',
  PRE_DEFINED_RECHARGE_OPTIONS: 'PRE_DEFINED_RECHARGE_OPTIONS',
  INPUT_RECHARGE_VALUE: 'INPUT_RECHARGE_VALUE',
  WALLET_BALANCE_SUCCESSFULLY_ADDED: 'WALLET_BALANCE_SUCCESSFULLY_ADDED',
  ADD_WALLET_BALANCE_ERROR: 'ADD_WALLET_BALANCE_ERROR'
};

export const EVENTS = {
  INIT: 'INIT',
  CONTINUE: 'CONTINUE',
  CONFIRM: 'CONFIRM',
  BACK: 'BACK',
  CLEAR: 'CLEAR',
  EXIT: 'EXIT',

  SET_DELIVERY_CUSTOMER: 'SET_DELIVERY_CUSTOMER',

  // content declaration
  SET_DECLARED_VALUE: 'SET_DECLARED_VALUE',

  // pickup schedule
  NO_PICKUP_SCHEDULE: 'NO_PICKUP_SCHEDULE',

  // budget
  CLICK_PICKUP_ADDRESS: 'CLICK_PICKUP_ADDRESS',
  CLICK_DELIVERY_ADDRESS: 'CLICK_DELIVERY_ADDRESS',
  SELECT_PICKUP_TO_REUSE: 'SELECT_PICKUP_TO_REUSE',
  SELECT_CONTINUE_SHIPMENT: 'SELECT_CONTINUE_SHIPMENT',
  SELECT_SERVICE_TYPE: 'SELECT_SERVICE_TYPE',

  CLICK_QUOTATION_WITH_POSTAL_CODE: 'CLICK_QUOTATION_WITH_POSTAL_CODE',
  CLICK_QUOTATION_WITH_ADDRESS: 'CLICK_QUOTATION_WITH_ADDRESS',

  SET_ORIGIN_ADDRESS: 'SET_ORIGIN_ADDRESS',
  SET_DELIVERY_ADDRESS: 'SET_DESTINATION_ADDRESS',

  CLICK_WALLET_CARD: 'CLICK_WALLET_CARD',

  // optional packaging
  SELECT_PACKAGING_SERVICE: 'SELECT_PACKAGING_SERVICE',
  SELECT_PACKAGE_SIZE: 'SELECT_PACKAGE_SIZE',
  SET_PACKAGE_DIMENSIONS: 'SET_PACKAGE_DIMENSIONS',
  VIEW_ABOUT_PACKAGING: 'VIEW_ABOUT_PACKAGING',

  // summary
  EDIT_PICKUP_ADDRESS: 'EDIT_PICKUP_ADDRESS',
  EDIT_DELIVERY_ADDRESS: 'EDIT_DELIVERY_ADDRESS',
  VIEW_COUPONS: 'VIEW_COUPONS',
  ORDER_CREATED: 'ORDER_CREATED',
  INVALID_PICKUP_SCHEDULE: 'INVALID_PICKUP_SCHEDULE',

  // coupons
  SET_COUPON: 'SET_COUPON',

  // payment
  SELECT_PIX_PAYMENT: 'SELECT_PIX_PAYMENT',
  SET_PAYMENT_METHOD: 'SET_PAYMENT_METHOD',
  CANCEL_PIX_PAYMENT: 'CANCEL_PIX_PAYMENT',
  BALANCE_SUCCESSFULLY_ADDED: 'BALANCE_SUCCESSFULLY_ADDED',

  // wallet
  CLICK_ADD_WALLET_BALANCE: 'CLICK_ADD_WALLET_BALANCE',
  CLICK_OTHER_VALUE: 'CLICK_OTHER_VALUE',
  VIEW_WALLET_BALANCE_ERROR: 'VIEW_WALLET_BALANCE_ERROR',
  SET_CURRENT_BALANCE: 'SET_CURRENT_BALANCE'
};

/** @type {import('./types.d').MachineContext} */
export const INITIAL_CONTEXT = {
  companyId: null,
  pickupAddress: null,
  deliveryAddress: null,
  originAddress: null,
  destinationAddress: null,
  pickupCustomer: null,
  deliveryCustomer: null,
  pickupSchedule: null,
  serviceType: null,
  coupon: null,
  insurance: null,
  pickupOrderScheduleId: null,

  // control
  backToSummary: false,
  featureSwitches: null,
  pendingContext: null,

  // shipment order
  shipmentOrder: null,

  // optional packaging
  packagingService: NOT_INFORMED,
  packageSize: null,
  packageDimensions: null,

  // payment
  creditCardPaymentMethod: null,

  // wallet
  rechargeValue: null,
  rechargeFrom: null,
  rechargeId: null,
  paymentMethod: null,
  currentBalance: null,
  paymentDate: null
};

/** @type {Array<keyof import("./types").MachineContext>} */
export const PERSISTENCE_FIELDS = [
  'originAddress',
  'destinationAddress',
  'pickupAddress',
  'deliveryAddress',
  'pickupCustomer',
  'deliveryCustomer',
  'pickupSchedule',
  'packagingService',
  'packageSize',
  'packageDimensions',
  'serviceType',
  'insurance',
  'rechargeValue',
  'rechargeFrom',
  'paymentMethod',
  'addBalanceFrom'
];

/** @type {Array<keyof import("./types").MachineContext>} */
export const AUTORECOVER_FIELDS = ['pickupAddress', 'originAddress'];

export const FROM_CLIENTES_APP_WEB = 'FROM_CLIENTES_APP_WEB';

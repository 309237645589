import React from 'react';
import PropTypes from 'prop-types';
import { BaseDrawer } from 'UI/components';
import { useTranslation } from 'react-i18next';
import {
  useShipmentContext,
  useShipmentDispatcher
} from 'UI/shipment/state-machine/context';
import { useSupport } from 'hooks';

/**
 * @param {object} props
 * @param {boolean} props.isVisible
 */
export default function AttemptCreateOrderLimitDrawer({ isVisible }) {
  const { t } = useTranslation('ui');
  const { context } = useShipmentContext();
  const shipmentDispatcher = useShipmentDispatcher();
  const support = useSupport({ companyId: context?.companyId });

  const handleDismissButton = () => {
    shipmentDispatcher.clear();
  };

  const handleConfirmButton = () => {
    window.open(support.supportPage, '_blank', 'noopener');
  };

  return (
    <BaseDrawer
      isOpen={isVisible}
      title={t('payment.AttemptCreateOrderLimitDrawer.title')}
      description={t(
        'payment.AttemptCreateOrderLimitDrawer.descriptionByCepAddress'
      )}
      labels={{
        confirm: t(
          'payment.AttemptCreateOrderLimitDrawer.accessHelpCenterLabel'
        ),
        dismiss: t(
          'payment.AttemptCreateOrderLimitDrawer.createShipmentAgainLabel'
        )
      }}
      onConfirmClick={handleConfirmButton}
      onDismissClick={handleDismissButton}
      vertical
      alignTitleLeft
      alignDescriptionLeft
    />
  );
}

AttemptCreateOrderLimitDrawer.propTypes = {
  isVisible: PropTypes.bool.isRequired
};

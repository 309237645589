import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS, { ADDRESS_BY } from 'crm/constants';
import { getPackageSizeDimensions } from 'UI/shipment/state-machine/utils';
import {
  getServiceTypeValue,
  getPackagingValue,
  getPaymentype,
  formatFullAddress,
  formatCityCorreios,
  getNewAppPreviousShipmentData
} from 'crm/utils';

export default class ShipmentCreationCompleted extends CRMBaseEvent {
  constructor({
    trackingKey,
    packaging,
    serviceType,
    addressBy,
    originFullAddress,
    destinationFullAddress,
    originCity,
    destinationCity,
    recipientName,
    price,
    coupon,
    goodsvalue,
    dimensions,
    paymentType,
    reference,
    previousShipmentData
  }) {
    super();
    this.eventName = EVENTS.SHIPMENT_CREATION_COMPLETED;
    this.payload = {
      loggi_tracking_key: trackingKey,
      shipment_type: serviceType,
      packaging,
      address_by: addressBy,
      origin_full_address: originFullAddress,
      destination_full_address: destinationFullAddress,
      origin_city: originCity,
      destination_city: destinationCity,
      recipient_name: recipientName,
      price: price ? Number(price) : 0,
      coupon,
      goodsvalue: Number(goodsvalue),
      dimensions,
      payment_type: paymentType,
      reference,
      ...previousShipmentData
    };
  }

  /**
   * @param {{ context: import('UI/shipment/state-machine/types').MachineContext }}
   */
  static fromShipmentContext({ context }) {
    const originAddress = context?.pickupAddress ?? context?.originAddress;
    const destinationAddress =
      context?.deliveryAddress ?? context?.destinationAddress;
    const rechargeId = context.shipmentOrder?.rechargeId;

    return new ShipmentCreationCompleted({
      trackingKey: context.shipmentOrder.trackingKey,
      packaging: getPackagingValue(context.packagingService),
      serviceType: getServiceTypeValue(context.serviceType),
      addressBy: context.pickupAddress ? ADDRESS_BY.GOOGLE : ADDRESS_BY.CEP,
      originFullAddress: formatFullAddress(originAddress),
      destinationFullAddress: formatFullAddress(destinationAddress),
      originCity:
        context.pickupAddress?.getCityState() ||
        formatCityCorreios(context.originAddress.correios),
      destinationCity:
        context.deliveryAddress?.getCityState() ||
        formatCityCorreios(context.destinationAddress.correios),
      recipientName: context.deliveryCustomer.name,
      price: context.shipmentOrder.price,
      coupon: context.coupon,
      goodsvalue: context.insurance,
      dimensions: getPackageSizeDimensions(context),
      paymentType: getPaymentype(context?.paymentMethod),
      reference: rechargeId,
      previousShipmentData: getNewAppPreviousShipmentData()
    });
  }
}

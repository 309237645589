import React from 'react';
import PropTypes from 'prop-types';

import { Box, IconButton, Stack, Typography } from '@mui/material';
import { Icon } from '@loggi/loggi-icons';
import { spacing, colors } from '@loggi/front-design-tokens';

import { useShipmentDispatcher } from 'UI/shipment/state-machine/context';

function CheckoutHeader({ title, goBack }) {
  const shipmentDispatcher = useShipmentDispatcher();

  return (
    <Box pt={spacing.stack.medium}>
      <Stack>
        <Box px={spacing.inset.xxsmall} my={spacing.stack.nano}>
          <IconButton
            data-testid="header-back-button"
            variant="blank"
            size="medium"
            color="secondary"
            onClick={() => (goBack ? goBack() : shipmentDispatcher.back())}
          >
            <Icon
              name="ArrowLeft"
              color={colors.interaction['on-secondary']}
              size="medium"
            />
          </IconButton>
        </Box>
        {title && (
          <Box px={spacing.inset.large} py={spacing.inset.xxxsmall}>
            <Typography
              variant="headingMedium"
              color={colors.neutrals['on-surface']}
            >
              {title}
            </Typography>
          </Box>
        )}
      </Stack>
    </Box>
  );
}

CheckoutHeader.defaultProps = {
  title: '',
  goBack: undefined
};

CheckoutHeader.propTypes = {
  title: PropTypes.string,
  goBack: PropTypes.func
};

export default CheckoutHeader;

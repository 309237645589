/* eslint-disable react/prop-types */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Divider, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { colors } from '@loggi/front-design-tokens';
import { loggiDesignSystem as theme } from '@loggi/front-design-system';

import { grey, green } from 'theme/colors';
import { Button, TrackingCodeDrawer } from 'UI/components';
import { ReactComponent as HandWithMobileIllustration } from 'assets/hand-with-mobile.svg';
import {
  useShipmentContext,
  useShipmentDispatcher
} from 'UI/shipment/state-machine/context';
import { clientesAppHome, newHome, occasionalTracking } from 'routes/routes';
import PACKAGE_SIZES from 'constants/package-size.constants';
import { AmateurQuoting } from 'models';
import { fillCompanyId } from 'utils';

import { ShipmentInfo } from '../indispatch/success_v2/shipment-info/shipment-info.component';

export default function ShipmentCreatedV2() {
  const { context } = useShipmentContext();
  const dispatcher = useShipmentDispatcher();
  const history = useHistory();
  const { t } = useTranslation('ui');
  const isBeyondOrder = AmateurQuoting.isBeyond(context.serviceType);
  const [isTrackingDrawerOpen, setIstrackingDrawerOpen] = React.useState(
    isBeyondOrder
  );

  const handleCreateShipment = () => {
    if (context.quotingFromClientesApp) {
      const clientesAppHomeURL = fillCompanyId({
        companyId: context.companyId,
        route: clientesAppHome
      });
      window.localStorage.removeItem('destinationAddress');
      window.localStorage.removeItem('shipmentPayload');
      window.location.href = `${window.location.origin}${clientesAppHomeURL}`;
    } else {
      dispatcher.continue();
      history.push(newHome.replace(':companyId', context.companyId));
    }
  };

  const handleViewDetails = () => {
    dispatcher.continue();
    history.push(
      occasionalTracking
        .replace(':companyId', context.companyId)
        .replace(':loggiKey', context.shipmentOrder.loggiKey)
    );
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          data-testid="shipment-created"
        >
          <Box
            alignItems="center"
            bgcolor={green[500]}
            color={colors.neutrals.surface}
            display="flex"
            justifyContent="space-between"
            p={3}
            pt={10}
          >
            <Box>
              <Typography
                variant="subtitleLarge"
                color={colors.neutrals.surface}
                fontWeight={400}
              >
                {t('shipmentCreated.successTitle')}
              </Typography>
              <Typography variant="bodyTextMedium">
                {t('shipmentCreated.successSubtitle')}
              </Typography>
            </Box>
            <SuccessIcon />
          </Box>
          <Box alignItems="center" display="flex" gap={3} p={3}>
            <Box height="80px">
              <HandWithMobileIllustration />
            </Box>
            <Box>
              <Typography variant="body1" color={colors.neutrals['on-surface']}>
                {isBeyondOrder
                  ? t('shipmentCreated.furtherInformationsTitleLoggiFacil')
                  : t('shipmentCreated.furtherInformationsTitle')}
              </Typography>
              <Typography sx={{ color: grey[200] }} variant="bodyTextMedium">
                {isBeyondOrder ? (
                  <Trans
                    t={t}
                    i18nKey="shipmentCreated.furtherInformationsSubtitleLoggiFacil"
                    values={{
                      trackingKey: context.shipmentOrder.trackingKey
                    }}
                  />
                ) : (
                  t('shipmentCreated.furtherInformationsSubtitle')
                )}
              </Typography>
            </Box>
          </Box>
          <Divider color={colors.neutrals['outline-low']} />
          <ShipmentInfo
            deliveryAddress={
              context.deliveryAddress ??
              context.destinationAddress.toStructured()
            }
            returnAddress={
              context.pickupAddress ?? context.originAddress.toStructured()
            }
            dimensions={
              context.packageDimensions ?? PACKAGE_SIZES[context.packageSize]
            }
            packagingService={context.packagingService}
          />
          <Box display="flex" flexDirection="column" gap={1} p={3}>
            <Button
              fullWidth
              label={t('shipmentCreated.actions.createShipment')}
              onClick={handleCreateShipment}
            />
            <Button
              fullWidth
              label={t('shipmentCreated.actions.viewDetails')}
              onClick={handleViewDetails}
              fontWeight={700}
              textColor={colors.interaction.primary}
              variant="text"
            />
          </Box>
        </Box>
      </ThemeProvider>

      {isTrackingDrawerOpen && (
        <TrackingCodeDrawer
          trackingCode={context.shipmentOrder?.trackingKey}
          onClose={() => setIstrackingDrawerOpen(false)}
        />
      )}
    </>
  );
}

function SuccessIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 5C9.92428 5 5 9.92428 5 16C5 22.0757 9.92428 27 16 27C22.0757 27 27 22.0757 27 16C27 9.92428 22.0757 5 16 5ZM3 16C3 8.81972 8.81972 3 16 3C23.1803 3 29 8.81972 29 16C29 23.1803 23.1803 29 16 29C8.81972 29 3 23.1803 3 16Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0408 12.6261C22.4313 13.0167 22.4313 13.6498 22.0408 14.0404L15.3741 20.707C14.9836 21.0975 14.3504 21.0975 13.9599 20.707L9.95989 16.707C9.56936 16.3165 9.56936 15.6833 9.95989 15.2928C10.3504 14.9023 10.9836 14.9023 11.3741 15.2928L14.667 18.5857L20.6266 12.6261C21.0171 12.2356 21.6502 12.2356 22.0408 12.6261Z"
        fill="white"
      />
    </svg>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '@mui/material';
import { colors, spacing } from '@loggi/front-design-tokens';
import { currencyFormatter } from 'utils';
import ValidatedCoupon from 'UI/shipment/models/validated-coupon.model';

/**
 * @param {import('UI/shipment/models/validated-coupon.model').default} validatedCoupon
 * @param {number} subtotal
 */
export default function EstimatePrice({ validatedCoupon, subtotal }) {
  const { t } = useTranslation('ui');

  let couponAmount;
  let total = subtotal;

  if (validatedCoupon) {
    couponAmount =
      validatedCoupon.totalAmount - validatedCoupon.amountWithAppliedDiscount;
    total = validatedCoupon.amountWithAppliedDiscount;
  }

  return (
    <Stack gap={spacing.stack.xxxsmall}>
      {validatedCoupon && (
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="bodyTextMedium" color={colors.neutrals.surface}>
            {t('checkoutFooter.estimatePrice.subtotal')}
          </Typography>
          <Typography
            variant="bodyTextMedium"
            color={colors.neutrals.surface}
            data-testid="subtotal"
          >
            {currencyFormatter(subtotal)}
          </Typography>
        </Stack>
      )}
      {validatedCoupon && (
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="bodyTextMedium" color={colors.neutrals.surface}>
            {t('checkoutFooter.estimatePrice.coupon')}
          </Typography>
          <Typography
            variant="bodyTextMedium"
            color={colors.neutrals.surface}
            data-testid="coupon-amount"
          >
            -{currencyFormatter(couponAmount)}
          </Typography>
        </Stack>
      )}
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="bodyTextMedium" color={colors.neutrals.surface}>
          {t('checkoutFooter.estimatePrice.total')}
        </Typography>
        {total >= 0 && (
          <Typography
            variant="bodyHighlightLarge"
            color={colors.neutrals.surface}
            data-testid="total"
          >
            {currencyFormatter(total)}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}

EstimatePrice.defaultProps = {
  validatedCoupon: null
};

EstimatePrice.propTypes = {
  validatedCoupon: PropTypes.instanceOf(ValidatedCoupon),
  subtotal: PropTypes.number.isRequired
};

import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { companyIdGuard, newHome, signUpCompany } from 'routes/routes';
import * as Sentry from '@sentry/browser';
import Loader from 'UI/components/loader/loader.component';
import { updateInsiderUserObject } from 'mobile';
import { useDomainUseCase } from 'UI/contexts';
import AccountCreationStarted from 'crm/entities/events/account-creation-started/account-creation-started';
import CRMUser from 'crm/entities/user/crm-user.model';
import { getSignUpOrigin } from 'crm/utils';
import companiesAccountApi from 'service/companies-account-api';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import { useCurrentUser } from 'auth';
import { useQueryParams } from 'hooks';
import { fillCompanyId, openEnvios } from 'utils';
import { REDIRECT_SIGN_UP_ENVIOS_KEY } from 'constants/auth.constants';
import useIsSinglePointOfAccessEnabled from 'hooks/single-point-of-access-enabled/single-point-of-access-enabled.hook';
import useNewAppExperiencePath from 'hooks/new-app-experience-path/new-app-experience-path.hook';

const urlWithState = ({ route }) => {
  return {
    pathname: route,
    state: { from: companyIdGuard }
  };
};

export const CompanyIdGuard = () => {
  const history = useHistory();
  const { state } = history.location;
  const { redirect } = useQueryParams();
  const [loading, setLoading] = useState(true);
  const [companyId, setCompanyId] = useState(null);
  const newAppExperience = useNewAppExperiencePath(companyId);
  const getCurrentUser = useDomainUseCase('getCurrentUserUseCase');
  const [isCreateAccessEnabled, setIsCreateAccessEnabled] = useState(null);
  const isCreateAccessEnabledUseCase = useDomainUseCase(
    'isCreateAccessEnabledUseCase'
  );
  const { fetchCurrentUserFromCognito } = useAmplifyAuth();
  const cognitoCurrentUser = useCurrentUser();
  let provider;
  const isSinglePointOfAccessEnabled = useIsSinglePointOfAccessEnabled(
    redirect
  );
  const isToRedirectToEnvios =
    isSinglePointOfAccessEnabled &&
    redirect === REDIRECT_SIGN_UP_ENVIOS_KEY &&
    state?.from === signUpCompany;

  useEffect(() => {
    if (isCreateAccessEnabled === null) {
      isCreateAccessEnabledUseCase().then(setIsCreateAccessEnabled);
    }
  }, [isCreateAccessEnabled, isCreateAccessEnabledUseCase]);

  const handleCompanyId = useCallback(
    async companyIdFromCognito => {
      let userCompanyId = companyIdFromCognito;
      try {
        const {
          company_id: companyIdFromDB
        } = await companiesAccountApi.createCustomAccessIfHasCompany();
        userCompanyId = companyIdFromDB;
        fetchCurrentUserFromCognito();
      } catch (error) {
        if (error.status === 500) {
          Sentry.captureException(error);
        }
      }
      return userCompanyId;
    },
    [fetchCurrentUserFromCognito]
  );

  const getUserInfos = useCallback(async () => {
    const currentUser = await getCurrentUser();
    let userCompanyId = currentUser?.companyId;
    if (!userCompanyId && isCreateAccessEnabled) {
      userCompanyId = await handleCompanyId(userCompanyId);
    }

    if (currentUser?.companyId) {
      updateInsiderUserObject({
        email: currentUser?.email,
        companyId: currentUser?.companyId.toString()
      });
    }

    setCompanyId(userCompanyId);
    setLoading(false);
  }, [getCurrentUser, handleCompanyId, isCreateAccessEnabled]);

  useEffect(() => {
    getUserInfos();
  }, [getUserInfos]);

  const sendEventToCrm = () => {
    const queryParams = new URLSearchParams(history.location?.search);
    const isSignInTheLastRoute =
      queryParams.has('code') && queryParams.has('state');
    provider = cognitoCurrentUser.identities?.[0].providerName;

    if (isSignInTheLastRoute && provider) {
      const event = new AccountCreationStarted({
        name: cognitoCurrentUser.name,
        origin: getSignUpOrigin(redirect),
        email: cognitoCurrentUser.email,
        provider
      });
      event.sendToCrm();

      new CRMUser({
        firstName: cognitoCurrentUser.name,
        email: cognitoCurrentUser.email
      }).sendToCrm();
    }
  };

  if (loading) return <Loader />;

  if (!companyId) {
    if (cognitoCurrentUser) {
      sendEventToCrm();
    }

    let queryParam = redirect ? `?redirect=${redirect}` : '';
    const prefix = redirect ? '&' : '?';
    queryParam = provider
      ? `${queryParam}${prefix}provider=${provider}`
      : queryParam;

    return <Redirect from="*" to={`${signUpCompany}${queryParam}`} />;
  }

  if (newAppExperience.isLoading) return <Loader />;

  if (isToRedirectToEnvios) {
    openEnvios();
    return <Loader />;
  }

  const route = newAppExperience.isEnabled
    ? newAppExperience.newAppExperiencePath
    : fillCompanyId({ route: newHome, companyId });

  if (newAppExperience.isEnabled) {
    const url = `${process.env.REACT_APP_AWS_REDIRECT_SIGN_IN_APP}${route}`;
    window.location.href = url;
  }

  return <Redirect from="*" to={urlWithState({ route })} />;
};

export default CompanyIdGuard;

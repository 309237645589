import { useAmplifyAuth } from '@loggi/authentication-lib';
import { Box, Link } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useSnackbar, useUTMs } from 'hooks';
import useIsSinglePointOfAccessEnabled from 'hooks/single-point-of-access-enabled/single-point-of-access-enabled.hook';
import useQueryParams from 'hooks/query-params/query-params.hook';
import { logNativeAnalyticsEvent } from 'mobile';
import React, { useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { companyIdGuard, login, signUpCompany } from 'routes/routes';
import checkIsMobile from 'utils/check-is-mobile/check-is-mobile.helper';
import companiesAccountApi from 'service/companies-account-api';
import SignUpCompanyUI from 'UI/pages/sign-up/company/sign-up-company.component';
import AccountCreationSubmitted from 'crm/entities/events/account-creation-submitted/account-creation-submitted';
import AccountCreationCompletedRoas from 'crm/entities/events/account-creation-completed-roas/account-creation-completed-roas';
import CRMUser from 'crm/entities/user/crm-user.model';
import AccountCreationCompleted from 'crm/entities/events/account-creation-completed/account-creation-completed';
import AccountCreationFailed from 'crm/entities/events/account-creation-failed/account-creation-failed';
import AccountCreationCompletedPlus30 from 'crm/entities/events/account-creation-completed_plus_30/account-creation-completed_plus_30';
import AccountCreationCompletedPj from 'crm/entities/events/account-creation-completed-pj/account-creation-completed-pj';
import AccountCreationCompletedRoasPj from 'crm/entities/events/account-creation-completed-roas-pj/account-creation-completed-roas-pj';
import {
  getSignUpOrigin,
  isEnabledToSendAccountCreationCompletedPlus30
} from 'crm/utils';
import {
  REDIRECT_SIGN_UP_CORP_KEY,
  REDIRECT_SIGN_UP_ENVIOS_KEY
} from 'constants/auth.constants';
import { useCorp } from 'UI/contexts/corp-middleware/corp-middleware.context';
import { USER_TYPE } from 'crm/constants';

const CPF_ALREADY_EXISTS_API_ERROR = 'empresa com este cpf já existe.';
const CPF_REGISTERED_WITH_ANOTHER_EMAIL_API_ERROR =
  'CPFRegisteredForAnotherEmail: CPF is already registered for another email';
const DRIVER_NOT_ALLOWED_TO_CREATE_COMPANY_API_ERROR =
  'Driver user not allowed to create a company.';
const HELP_REQUEST_LINK = 'https://ajuda.loggi.com/hc/pt-br/requests/new';
const IRREGULAR_CPF = 'This field must be a valid CPF.';
const IRREGULAR_CPF_SHARED_NAME =
  'The name informed has not similar with the name registered.';
const CPF_DOES_NOT_MATCH =
  'Created company and existing user CPFs did not match.';
const EMAIL_DOES_NOT_MATCH_CODE = 'emailDoesNotMatchError';
const CREATE_PERSONAL_CUSTOMER_ERROR = 'createPersonalCustomerError';

const SignUpCompany = () => {
  const { t } = useTranslation('containers');
  const isMobile = checkIsMobile();
  const history = useHistory();
  const showSnackbar = useSnackbar();
  const utms = useUTMs().getValue();
  const { redirect } = useQueryParams();
  const isSinglePointOfAccessEnabled = useIsSinglePointOfAccessEnabled(
    redirect
  );
  const isRedirectToCorpOnSignUp =
    redirect === REDIRECT_SIGN_UP_CORP_KEY && isSinglePointOfAccessEnabled;
  const isRedirectToEnviosOnSignUp =
    redirect === REDIRECT_SIGN_UP_ENVIOS_KEY && isSinglePointOfAccessEnabled;

  const {
    fetchCurrentUserFromCognito,
    signOut,
    state: { error: stateError, authenticatedUser }
  } = useAmplifyAuth();
  const signupError = stateError;
  const { openCorp } = useCorp();

  const handleOnGoBackClick = useCallback(
    () => signOut().then(() => history.push('/')),
    [signOut, history]
  );

  const getKnownErrorsMessageFromJSON = message => {
    try {
      const errorObject = JSON.parse(message);
      if (errorObject) {
        // eslint-disable-next-line camelcase
        const [errorMessage] = errorObject.cpf || errorObject.non_field_errors;
        return errorMessage;
      }
      return null;
    } catch {
      return null;
    }
  };

  const getErrorMessage = useCallback(
    error => {
      const errorMessage = getKnownErrorsMessageFromJSON(error.message);
      if (
        errorMessage === CPF_ALREADY_EXISTS_API_ERROR ||
        errorMessage === CPF_REGISTERED_WITH_ANOTHER_EMAIL_API_ERROR ||
        error.message?.includes(EMAIL_DOES_NOT_MATCH_CODE)
      ) {
        const linkTag = (
          <Link
            target="_blank"
            href={HELP_REQUEST_LINK}
            underline="always"
            color="inherit"
          />
        );
        return (
          <Trans
            t={t}
            i18nKey="signUp.errorMessages.cpfAlreadyExists"
            components={[linkTag]}
            detail={error.message}
          />
        );
      }
      if (
        errorMessage === IRREGULAR_CPF ||
        errorMessage === IRREGULAR_CPF_SHARED_NAME ||
        errorMessage?.toLowerCase().includes('bigdatacorp')
      )
        return t('signUp.errorMessages.cpfIrregular');
      if (
        errorMessage === DRIVER_NOT_ALLOWED_TO_CREATE_COMPANY_API_ERROR ||
        error.message?.includes(CREATE_PERSONAL_CUSTOMER_ERROR)
      )
        return t('signUp.errorMessages.driverNotAllowed');
      if (error.message === CPF_DOES_NOT_MATCH)
        return t('signUp.errorMessages.cpfDoesNotMatch');
      if (error.status === 500) {
        return t('signUp.errorMessages.error500');
      }
      Sentry.captureException(error);
      return t('signUp.errorMessages.genericError', { detail: error.message });
    },
    [t]
  );

  const sendSignUpEventToAnalytics = () => {
    const event = 'beyond_signUpSuccess';
    window.dataLayer?.push({ event });
    logNativeAnalyticsEvent(event);
  };
  const sendPreSignupCrmEvent = companyData => {
    new AccountCreationSubmitted({
      email: authenticatedUser?.email || companyData?.email,
      name: authenticatedUser?.name || companyData?.name,
      shipmentQualification: companyData?.usuallySend,
      volumeQualification: companyData?.estimatedMonthlyPackages,
      ...companyData
    }).sendToCrm();
  };

  const sendPostSignupCrmEvents = (response, companyData) => {
    const email = authenticatedUser?.email;
    const crmUser = CRMUser.fromCompanySignup(
      {
        ...companyData,
        companyId: response.id,
        phoneFromValidationBase: response.landline_1
      },
      email
    );
    crmUser.sendToCrm();

    if (companyData?.userType === USER_TYPE.CORPORATE_USER) {
      new AccountCreationCompletedPj({
        ...companyData,
        companyId: response.id,
        name: authenticatedUser?.name,
        email,
        origin: getSignUpOrigin(redirect),
        phoneFromValidationBase: response.landline_1,
        cnpj: response?.cnpj,
        cpf: response?.cpf,
        shipmentQualification: companyData?.usuallySend,
        volumeQualification: companyData?.estimatedMonthlyPackages,
        estimatedMonthlyPackages: companyData?.estimatedMonthlyPackages
      }).sendToCrm();

      new AccountCreationCompletedRoasPj({
        companyId: response.id,
        name: authenticatedUser?.name,
        email,
        origin: getSignUpOrigin(redirect),
        phone: response.landline_1,
        cnpj: response?.cnpj,
        cpf: response?.cpf,
        price: companyData?.estimatedMonthlyPackages
      }).sendToCrm();
    }

    if (isEnabledToSendAccountCreationCompletedPlus30(companyData)) {
      new AccountCreationCompletedPlus30({
        ...companyData,
        companyId: response.id,
        name: authenticatedUser?.name,
        email,
        origin: getSignUpOrigin(redirect),
        phoneFromValidationBase: response.landline_1,
        cnpj: response?.cnpj,
        cpf: response?.cpf,
        shipmentQualification: companyData?.usuallySend,
        volumeQualification: companyData?.estimatedMonthlyPackages,
        estimatedMonthlyPackages: companyData?.estimatedMonthlyPackages
      }).sendToCrm();
    }

    new AccountCreationCompleted({
      ...companyData,
      companyId: response.id,
      name: authenticatedUser?.name,
      email,
      origin: getSignUpOrigin(redirect),
      phoneFromValidationBase: response.landline_1,
      cnpj: response?.cnpj,
      cpf: response?.cpf,
      shipmentQualification: companyData?.usuallySend,
      volumeQualification: companyData?.estimatedMonthlyPackages,
      estimatedMonthlyPackages: companyData?.estimatedMonthlyPackages
    }).sendToCrm();

    new AccountCreationCompletedRoas({
      companyId: response.id,
      name: authenticatedUser?.name,
      email,
      origin: getSignUpOrigin(redirect),
      phone: response.landline_1,
      cnpj: response?.cnpj,
      cpf: response?.cpf,
      price: companyData?.estimatedMonthlyPackages
    }).sendToCrm();
  };

  const handleSignUpCompany = async companyData => {
    sendPreSignupCrmEvent(companyData);

    try {
      const response = await companiesAccountApi.signupCompany(
        companyData,
        utms
      );
      sendSignUpEventToAnalytics();
      fetchCurrentUserFromCognito();
      sendPostSignupCrmEvents(response, companyData);
      if (isRedirectToCorpOnSignUp) {
        await openCorp(true);
        return;
      }
      if (isRedirectToEnviosOnSignUp) {
        history.push({
          pathname: companyIdGuard,
          search: `?redirect=${REDIRECT_SIGN_UP_ENVIOS_KEY}`,
          state: { from: signUpCompany }
        });
        return;
      }
      history.push(login);
    } catch (error) {
      new AccountCreationFailed({
        shipmentQualification: companyData?.usuallySend,
        volumeQualification: companyData?.estimatedMonthlyPackages,
        document: companyData?.cnpj || companyData?.cpf,
        errorMessage: error?.message,
        errorStatus: error?.status,
        ...companyData
      }).sendToCrm();

      showSnackbar({ message: getErrorMessage(error), severity: 'error' });
      throw error;
    }
  };

  useEffect(() => {
    if (signupError)
      showSnackbar({
        message: getErrorMessage(signupError),
        severity: 'error'
      });
  }, [signupError, getErrorMessage, showSnackbar]);

  return (
    <Box
      style={{ height: '100vh' }}
      display={isMobile ? 'auto' : 'flex'}
      alignItems="center"
    >
      <SignUpCompanyUI
        onGoBack={handleOnGoBackClick}
        onSubmit={handleSignUpCompany}
      />
    </Box>
  );
};

export default SignUpCompany;

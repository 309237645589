import { PROVIDERS, ACCESS_EMAIL_LOGIN } from 'UI/pages/sign-in/sign-in.page';
import { ACCESS_EMAIL_SIGNUP } from 'UI/pages/sign-up/user/form/user-credentials-form.component';
import {
  CREDIT_CARD,
  PIX,
  WALLET_BALANCE
} from 'UI/shipment/pages/payment/constants';
import {
  SERVICES,
  PACKAGING,
  CREATION_METHOD,
  ACCESS_METHOD,
  DEFAULT_NULL_NUMBER,
  ADDRESS_BY,
  WALLET_CREDIT_ADDED_OPTIONS,
  NEW_MONTHLY_PACKAGE_RANGE_VALUES,
  SHIPPING_QUALIFICATION_OPTION
} from 'crm/constants';
import { getTimeString } from 'utils';
import { getPackageSizeDimensions } from 'UI/shipment/state-machine/utils';
import ShipmentCreationSent from 'crm/entities/events/shipment-creation-sent/shipment-creation-sent';
import ShipmentSentPurchase from 'crm/entities/events/shipment-sent-purchase/shipment-sent-purchase';
import ShipmentCreationFailed from 'crm/entities/events/shipment-creation-failed/shipment-creation-failed';
import {
  REDIRECT_SIGN_UP_CORP_KEY,
  REDIRECT_SIGN_UP_ENVIOS_KEY
} from 'constants/auth.constants';

export const getServiceTypeValue = serviceType => {
  switch (serviceType) {
    case 'AMATEUR_SERVICE_TYPE_NATIONAL_ECONOMIC':
      return SERVICES.LOGGI_FACIL;
    case 'AMATEUR_SERVICE_TYPE_LOCAL_EXPRESS':
      return SERVICES.EXPRESSO;
    case 'AMATEUR_SERVICE_TYPE_CORREIOS_PLP_PAC':
    case 'AMATEUR_SERVICE_TYPE_CORREIOS_PLP_SEDEX':
      return SERVICES.CORREIOS;
    case 'AMATEUR_SERVICE_TYPE_DROPOFF':
      return SERVICES.DROPOFF;
    default:
      return '';
  }
};

export const getAboutServiceNameValue = serviceName => {
  switch (serviceName) {
    case 'loggiFacil':
      return SERVICES.LOGGI_FACIL;
    case 'loggiExpresso':
      return SERVICES.EXPRESSO;
    case 'correios':
      return SERVICES.CORREIOS;
    case 'loggiPonto':
      return SERVICES.DROPOFF;
    default:
      return '';
  }
};

export const getWalletCreditAddedOption = paymentType => {
  switch (paymentType) {
    case PIX:
      return WALLET_CREDIT_ADDED_OPTIONS.PIX;
    case CREDIT_CARD:
      return WALLET_CREDIT_ADDED_OPTIONS.CARTAO;
    default:
      return '';
  }
};

export const getPackagingValue = packagingService => {
  switch (packagingService) {
    case 'NOT_INFORMED':
      return 'Não informado';
    case 'SIMPLE_PACKAGING':
      return PACKAGING.BY_LOGGI;
    case 'NO_PACKAGING':
      return PACKAGING.BY_CLIENT;
    default:
      return '';
  }
};

export const getPaymentype = paymentMethod => {
  switch (paymentMethod) {
    case PIX:
      return 'pix';
    case CREDIT_CARD:
      return 'cartao';
    case WALLET_BALANCE:
      return 'wallet';
    default:
      return '';
  }
};

export const getCreationMethod = provider => {
  const providerMap = {
    [PROVIDERS.GOOGLE]: CREATION_METHOD.GOOGLE,
    [PROVIDERS.APPLE]: CREATION_METHOD.APPLE,
    [ACCESS_EMAIL_SIGNUP]: CREATION_METHOD.EMAIL
  };
  return providerMap[provider] || '';
};

export const getMonthlyPackagesRangeRoasPrice = monthlyPackages => {
  switch (monthlyPackages) {
    case NEW_MONTHLY_PACKAGE_RANGE_VALUES.DEFAULT:
      return 50;
    case NEW_MONTHLY_PACKAGE_RANGE_VALUES.MICRO:
      return 400;
    case NEW_MONTHLY_PACKAGE_RANGE_VALUES.SMALL:
      return 1500;
    case NEW_MONTHLY_PACKAGE_RANGE_VALUES.MEDIUM:
      return 5000;
    case NEW_MONTHLY_PACKAGE_RANGE_VALUES.LARGE:
      return 15000;
    case NEW_MONTHLY_PACKAGE_RANGE_VALUES.EXTRA:
      return 17000;
    default:
      return '';
  }
};

const getNameParts = name => {
  const parts = (name || '').trim().split(/\s+/);
  return [parts[0] || '', parts.slice(1).join(' ') || ''];
};

export const getFirstName = name => getNameParts(name)[0];
export const getLastName = name => getNameParts(name)[1];

export const getAccessMethod = accessMethod => {
  const accessMethods = {
    [PROVIDERS.GOOGLE]: ACCESS_METHOD.GOOGLE,
    [PROVIDERS.APPLE]: ACCESS_METHOD.APPLE,
    [ACCESS_EMAIL_SIGNUP]: ACCESS_METHOD.EMAIL_SIGNUP,
    [ACCESS_EMAIL_LOGIN]: ACCESS_METHOD.EMAIL_LOGIN
  };
  return accessMethods[accessMethod] || '';
};

export const getDateTimeFromChosenSlot = pickupSchedule => {
  const startDate = new Date(pickupSchedule[0]);
  const endDate = new Date(pickupSchedule[pickupSchedule.length - 1]);

  const date = new Intl.DateTimeFormat('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  }).format(startDate);

  const startTime = getTimeString(startDate);
  const endTime = getTimeString(endDate);

  const timeRange = `${startTime} - ${endTime}`;

  return { date, time: timeRange };
};

export const convertToNumberOrDefault = param => {
  const number = Number(param);
  if ((!param && param !== 0) || Number.isNaN(number))
    return DEFAULT_NULL_NUMBER;
  return number;
};

export const formatPhoneNumber = phone => {
  if (!phone) return phone;
  const cleanedPhone = phone.replace(/[^a-zA-Z0-9]/g, '');
  if (cleanedPhone.startsWith('55')) return `+${cleanedPhone}`;
  return `+55${cleanedPhone}`;
};

export const formatFullAddress = address => {
  if (address?.correios) {
    const {
      logradouro,
      numero,
      bairro,
      cidade,
      uf,
      complemento,
      cep
    } = address.correios;

    const cityState = `${cidade} - ${uf}`;
    const formattedAddressParts = [
      logradouro,
      numero,
      bairro,
      cityState,
      complemento,
      cep
    ]
      .filter(part => !!part)
      .join(', ');
    return formattedAddressParts;
  }

  const complement = address?.complement ? `, ${address.complement}` : '';
  return `${address?.structured?.main}, ${
    address?.structured?.secondary
  }${complement}`;
};

export const formatCityCorreios = correiosAddress => {
  const cidade = correiosAddress.cidade ? `${correiosAddress.cidade} - ` : '';
  const uf = correiosAddress.uf ? correiosAddress.uf : '';

  return `${cidade}${uf}`;
};

export const sendShipmentSentEventsToCrm = ({ context, payload }) => {
  const price = payload?.['payment_data']?.amount;
  const coupon = payload?.coupon;
  const rechargeId = payload?.rechargeId;
  const eventInfo = {
    context,
    price,
    coupon,
    rechargeId
  };
  ShipmentCreationSent.fromShipmentContext(eventInfo).sendToCrm();
  ShipmentSentPurchase.fromShipmentContext(eventInfo).sendToCrm();
};

export const sendShipmentCreationFailedToCrm = (
  error,
  { context, payload }
) => {
  ShipmentCreationFailed.fromShipmentContext({
    context,
    price: payload?.['payment_data']?.amount,
    rechargeId: payload?.rechargeId,
    coupon: payload?.coupon,
    error
  }).sendToCrm();
};

export const buildShipmentSentEventsPayload = (
  context,
  price,
  coupon,
  utms,
  rechargeId
) => {
  const originAddress = context?.pickupAddress ?? context?.originAddress;
  const destinationAddress =
    context?.deliveryAddress ?? context?.destinationAddress;

  return {
    packaging: getPackagingValue(context.packagingService),
    serviceType: getServiceTypeValue(context.serviceType),
    addressBy: context.pickupAddress ? ADDRESS_BY.GOOGLE : ADDRESS_BY.CEP,
    originFullAddress: formatFullAddress(originAddress),
    destinationFullAddress: formatFullAddress(destinationAddress),
    originCity:
      context.pickupAddress?.getCityState() ??
      formatCityCorreios(context.originAddress?.correios) ??
      undefined,
    destinationCity:
      context.deliveryAddress?.getCityState() ??
      formatCityCorreios(context.destinationAddress?.correios) ??
      undefined,
    recipientName: context.deliveryCustomer.name,
    price,
    coupon,
    goodsvalue: context.insurance,
    paymentType: getPaymentype(context?.paymentMethod),
    dimensions: getPackageSizeDimensions(context),
    utms,
    reference: rechargeId
  };
};

export const getSignUpOrigin = redirectTo => {
  switch (redirectTo) {
    case REDIRECT_SIGN_UP_CORP_KEY:
      return REDIRECT_SIGN_UP_CORP_KEY;
    case REDIRECT_SIGN_UP_ENVIOS_KEY:
      return REDIRECT_SIGN_UP_ENVIOS_KEY;
    default:
      return 'beyond';
  }
};

export const getNewAppPreviousShipmentData = () => {
  return JSON.parse(localStorage.getItem('previousShipmentData'));
};

export const isEnabledToSendAccountCreationCompletedPlus30 = data => {
  const { SOU_ENTREGADOR, QUERO_RASTREAR } = SHIPPING_QUALIFICATION_OPTION;

  if (!data?.usuallySend || !data?.estimatedMonthlyPackages) {
    return false;
  }

  const { DEFAULT, MICRO, SMALL } = NEW_MONTHLY_PACKAGE_RANGE_VALUES;

  const isDisabledInShipmentQualification = [
    SOU_ENTREGADOR,
    QUERO_RASTREAR
  ].includes(data?.usuallySend);

  const isDisabledInMonthlyPackages = [DEFAULT, MICRO, SMALL].includes(
    data?.estimatedMonthlyPackages
  );

  return !isDisabledInShipmentQualification && !isDisabledInMonthlyPackages;
};

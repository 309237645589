/* eslint-disable react/jsx-props-no-spreading */
import { useActor, useInterpret } from '@xstate/react';
import PickupScheduleSlotSelected from 'crm/entities/events/pickup-schedule-slot-selected/pickup-schedule-slot-selected';
import ShipmentAddressByGoogleSelected from 'crm/entities/events/shipment-address-by-google-selected/shipment-address-by-google-selected';
import ShipmentContentDeclarationInputed from 'crm/entities/events/shipment-content-declaration-inputed/shipment-content-declaration-inputed';
import ShipmentCouponInputed from 'crm/entities/events/shipment-coupon-inputed/shipment-coupon-inputed';
import ShipmentCreationCompleted from 'crm/entities/events/shipment-creation-completed/shipment-creation-completed';
import ShipmentCreationStarted from 'crm/entities/events/shipment-creation-started/shipment-creation-started';
import ShipmentDestinationInputStarted from 'crm/entities/events/shipment-destination-input-started/shipment-destination-input-started';
import ShipmentDestinationInputed from 'crm/entities/events/shipment-destination-inputed/shipment-destination-inputed';
import ShipmentDimensionsInputed from 'crm/entities/events/shipment-dimensions-inputed/shipment-dimensions-inputed';
import ShipmentOriginInputed from 'crm/entities/events/shipment-origin-inputed/shipment-origin-inputed';
import ShipmentPackagingInputed from 'crm/entities/events/shipment-packaging-inputed/shipment-packaging-inputed';
import ShipmentRecipientInputed from 'crm/entities/events/shipment-recipient-inputed/shipment-recipient-inputed';
import ShipmentServiceSelected from 'crm/entities/events/shipment-service-selected/shipment-service-selected';
import WalletCreditAdded from 'crm/entities/events/wallet-credit-added/wallet-credit-added';
import ShipmentCreationCompletedRevenue from 'crm/entities/revenue-events/shipment-creation-completed/shipment-creation-completed';
import { omit, pick } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { assign } from 'xstate';

import {
  AUTORECOVER_FIELDS,
  EVENTS,
  INITIAL_CONTEXT,
  PERSISTENCE_FIELDS,
  STATES
} from './constants';
import { createShipmentMachine } from './shipment-machine';

/** @typedef {import('./types.d').MachineContext} MachineContext */

const ShipmentMachineContext = React.createContext(null);

const getStorageKey = companyId => `unstable_shipment_context_${companyId}`;

/** @param {MachineContext} storedContext */
const getNonEmptyStoredContext = storedContext => {
  const nonEmptyFields = PERSISTENCE_FIELDS.filter(
    field =>
      !!storedContext[field] && storedContext[field] !== INITIAL_CONTEXT[field]
  );
  if (nonEmptyFields.length === 0) return null;
  return pick(storedContext, nonEmptyFields);
};

function createPopStateListener(service) {
  return function onPopState() {
    service.send({ type: EVENTS.BACK });
  };
}

/** @type {import('./types.d').MachineActions} */
const defaultActions = {
  persist: ctx => {
    const filteredObject = pick(ctx, PERSISTENCE_FIELDS);
    const serializedContext = JSON.stringify(filteredObject);
    window.localStorage.setItem(
      getStorageKey(ctx.companyId),
      serializedContext
    );
  },
  /** Recommended when wants to completely clear persisted context */
  purgePersistedContext: ctx => {
    window.localStorage.removeItem(getStorageKey(ctx.companyId));
  },
  /** Recommended when wants to completely clear context (ex: signOut) */
  purgeContext: assign(INITIAL_CONTEXT),
  /** Recommended when wants to reset context (ex: start new order) */
  resetPersistedContext: ctx => {
    const newContext = pick(ctx, AUTORECOVER_FIELDS);
    const serializedContext = JSON.stringify(newContext);
    window.localStorage.setItem(
      getStorageKey(ctx.companyId),
      serializedContext
    );
  },
  /** Recommended when wants to reset context (ex: start new order) */
  resetContext: assign(
    omit(INITIAL_CONTEXT, [
      ...AUTORECOVER_FIELDS,
      'companyId',
      'featureSwitches'
    ])
  ),
  sendClickPickupAddress: () => {
    const event = new ShipmentCreationStarted();
    event.sendToCrm();
  },
  sendClickDeliveryAddress: () => {
    const event = new ShipmentDestinationInputStarted();
    event.sendToCrm();
  },
  sendSelectedServiceType: ctx => {
    const event = ShipmentServiceSelected.fromShipmentContext({
      context: ctx
    });
    event.sendToCrm();
  },
  sendWalletCreditAdded: ctx => {
    const { addedBalance, paymentMethod, rechargeId } = ctx;

    const event = new WalletCreditAdded({
      amount: addedBalance,
      reference: rechargeId,
      paymentType: paymentMethod
    });

    event.sendToCrm();
  },
  sendShipmentCouponInputed: ctx => {
    const event = new ShipmentCouponInputed({
      couponCode: ctx.coupon
    });
    event.sendToCrm();
  },
  sendShipmentCreationCompleted: context => {
    const event = ShipmentCreationCompleted.fromShipmentContext({ context });
    const revenueEvent = ShipmentCreationCompletedRevenue.fromShipmentContext({
      context
    });
    event.sendToCrm();
    revenueEvent.sendToCrm();
  },
  sendPickupAddressContinueClicked: ctx => {
    const event = new ShipmentOriginInputed({
      shipmentOrigin: ctx.pickupAddress.description
    });
    event.sendToCrm();
  },
  sendDeliveryAddressContinueClicked: ctx => {
    const event = new ShipmentDestinationInputed({
      shipmentDestination: ctx.deliveryAddress?.description
    });
    event.sendToCrm();
  },
  sendDimensionsContinueClicked: ctx => {
    const event = new ShipmentDimensionsInputed({
      height: ctx.packageDimensions?.heightCm,
      width: ctx.packageDimensions?.widthCm,
      length: ctx.packageDimensions?.lengthCm,
      weight: ctx.packageDimensions?.weightG
    });
    event.sendToCrm();
  },
  sendContentDeclarationContinueClicked: ctx => {
    const event = new ShipmentContentDeclarationInputed({
      goodsvalue: ctx.insurance
    });
    event.sendToCrm();
  },
  sendRequirePackagingContinueClicked: ctx => {
    const event = new ShipmentPackagingInputed({
      packagingService: ctx.packagingService
    });
    event.sendToCrm();
  },
  sendPickupScheduleSlotSelected: ctx => {
    const event = new PickupScheduleSlotSelected({
      chosenSlot: ctx.pickupSchedule
    });
    event.sendToCrm();
  },
  sendDeliveryCustomerContinueClicked: ctx => {
    const event = new ShipmentRecipientInputed({
      deliveryCustomer: ctx.deliveryCustomer
    });
    event.sendToCrm();
  },
  sendQuotationWithAddressClicked: () => {
    const event = new ShipmentAddressByGoogleSelected();
    event.sendToCrm();
  }
};

/**
 * Shipment machine provider creates one instance of the shipment machine
 * and starts the service that represents the running state machine.
 * Every children of this provider will have access to the state machine
 * service.
 *
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {function} props.onStateChange
 * @returns {React.ReactNode}
 */
export function ShipmentMachineProvider({
  children,
  onStateChange = () => {},
  actions = {},
  initialState = undefined,
  initialContext = undefined
}) {
  const machine = createShipmentMachine(initialContext, initialState);
  const service = useInterpret(
    machine,
    {
      actions: { ...defaultActions, ...actions }
    },
    state =>
      onStateChange({
        state: state.value,
        context: state.context,
        event: state._event.data
      })
  );

  service.onEvent(function eventListener(event) {
    /**
     * This piece of code adds the event listener when the machine starts,
     * that's when we start caring if the user pressed the back button
     */
    if (event.type === EVENTS.INIT) {
      window.addEventListener('popstate', createPopStateListener(service));
    }
  });

  service.onTransition(function transitionListener(event) {
    /**
     * This piece of code deals with the removal of the event listener that
     * keeps the machine in sync with clicks on the browser's back button.
     */
    if (event.value === STATES.IDLE) {
      window.removeEventListener('popstate', createPopStateListener(service));
    }
  });

  return (
    <ShipmentMachineContext.Provider value={service}>
      {children}
    </ShipmentMachineContext.Provider>
  );
}

ShipmentMachineProvider.propTypes = {
  children: PropTypes.node.isRequired,
  onStateChange: PropTypes.func,
  actions: PropTypes.objectOf(PropTypes.func),
  initialState: PropTypes.oneOf(Object.values(STATES)),
  initialContext: PropTypes.instanceOf(Object)
};

ShipmentMachineProvider.defaultProps = {
  onStateChange: () => {},
  actions: undefined,
  initialState: undefined,
  initialContext: undefined
};

export function useShipmentContext() {
  const service = React.useContext(ShipmentMachineContext);
  const [state] = useActor(service);

  return {
    isIdle: state.value === STATES.IDLE,
    /** @type {keyof typeof STATES} */
    state: state.value,
    /** @type {MachineContext} */
    context: state.context
  };
}

export function useShipmentDispatcher() {
  const service = React.useContext(ShipmentMachineContext);
  const [, send] = useActor(service);

  return {
    /**
     * Dispatches the init event.
     *
     * @todo Move the act of loading the context from local storage to the proper place.
     * @param {object} payload
     * @param {string} payload.companyId
     * @param {object} payload.featureSwitches
     * @param {boolean} payload.newHomeIsEnabled
     * @returns {void}
     */
    init: ({
      companyId,
      featureSwitches = {},
      newHomeIsEnabled = false,
      fromWallet = false,
      fromPayment = false,
      fromLoggiCheckout = false,
      fromAddBalanceOptions = false
    }) => {
      const serializedStoredContext = window.localStorage.getItem(
        getStorageKey(companyId)
      );
      const hasCurrentPixPaymentSession = JSON.parse(
        window.localStorage.getItem('pix_payment_session')
      );
      const storedContext = JSON.parse(serializedStoredContext);
      send({
        type: EVENTS.INIT,
        payload: {
          companyId,
          featureSwitches,
          newHomeIsEnabled,
          fromWallet,
          fromPayment,
          fromLoggiCheckout,
          fromAddBalanceOptions,
          hasCurrentPixPaymentSession,
          storedContext: storedContext
            ? getNonEmptyStoredContext(storedContext)
            : null
        }
      });
    },

    /**
     * Dispatches the continue event.
     * @param {P|object} payload
     * @returns {void}
     */
    continue: payload => send({ type: EVENTS.CONTINUE, payload }),

    /**
     * @returns {void}
     */
    back: () => send({ type: EVENTS.BACK }),

    /**
     * @returns {void}
     */
    clear: () => send({ type: EVENTS.CLEAR }),

    /**
     * @returns {void}
     */
    exit: () => send({ type: EVENTS.EXIT }),

    /**
     * Sets the delivery customer.
     *
     * @param {object} payload
     * @param {string} payload.name
     * @param {string} payload.phone
     * @param {string} payload.cnpjCpf
     * @returns {void}
     */
    setDeliveryCustomer: ({ name, phone, cnpjCpf }) =>
      send({
        type: EVENTS.SET_DELIVERY_CUSTOMER,
        payload: { name, phone, cnpjCpf }
      }),

    /**
     * Sets the origin address
     *
     * @param {import('../models/input-address.model').default} address
     * @returns {void}
     */
    setOriginAddress: address =>
      send({ type: EVENTS.SET_ORIGIN_ADDRESS, payload: address }),

    /**
     * Sets the destination address
     *
     * @param {import('../models/input-address.model').default} address
     * @returns {void}
     */
    setDestinationAddress: address =>
      send({ type: EVENTS.SET_DELIVERY_ADDRESS, payload: address }),

    /**
     * @returns {void}
     */
    clickWalletCard: () => send({ type: EVENTS.CLICK_WALLET_CARD }),

    /**
     * @returns {void}
     */
    clickPickupAddress: () => send({ type: EVENTS.CLICK_PICKUP_ADDRESS }),

    /**
     * @returns {void}
     */
    clickDeliveryAddress: () => send({ type: EVENTS.CLICK_DELIVERY_ADDRESS }),

    /**
     * @returns {void}
     */
    clickQuotationWithPostalCode: () =>
      send({ type: EVENTS.CLICK_QUOTATION_WITH_POSTAL_CODE }),

    /**
     * @returns {void}
     */
    clickQuotationWithAddress: () =>
      send({ type: EVENTS.CLICK_QUOTATION_WITH_ADDRESS }),

    /**
     * @returns {void}
     */
    clickAddWalletBalance: payload =>
      send({ type: EVENTS.CLICK_ADD_WALLET_BALANCE, payload }),

    /**
     * @returns {void}
     */
    cancelPixPayment: () => send({ type: EVENTS.CANCEL_PIX_PAYMENT }),

    /**
     * @returns {void}
     */
    clickOtherValue: () => send({ type: EVENTS.CLICK_OTHER_VALUE }),

    /**
     * @param {number} payload - The object declared value
     * @returns {void}
     */
    setDeclaredValue: payload =>
      send({ type: EVENTS.SET_DECLARED_VALUE, payload }),

    /**
     * @param {import('./types.d').PackagingService} payload
     * @returns {void}
     */
    selectPackagingService: payload =>
      send({ type: EVENTS.SELECT_PACKAGING_SERVICE, payload }),

    /**
     * @param {import('./types.d').PackageSize} payload - The package size (PP, P, M or G)
     * @returns {void}
     */
    selectPackageSize: payload =>
      send({ type: EVENTS.SELECT_PACKAGE_SIZE, payload }),

    /**
     * @param {import('./types.d').PackageDimensions} payload
     * @returns {void}
     */
    setPackageDimensions: payload =>
      send({ type: EVENTS.SET_PACKAGE_DIMENSIONS, payload }),

    /**
     * @returns {void}
     */
    viewAboutPackaging: () => send({ type: EVENTS.VIEW_ABOUT_PACKAGING }),

    /**
     * @returns {void}
     */
    viewWalletBalanceError: ({ paymentMethod, addBalanceError }) =>
      send({
        type: EVENTS.VIEW_WALLET_BALANCE_ERROR,
        payload: { paymentMethod, addBalanceError }
      }),

    /**
     * @returns {void}
     */
    setPaymentMethod: payload =>
      send({ type: EVENTS.SET_PAYMENT_METHOD, payload }),

    /**
     * @param {object} payload
     * @param {number} payload.pickupOrderScheduleId
     * @param {[Date, Date]} payload.pickupSchedule
     * @param {object} payload.pickupCustomer
     * @param {import('models').Address} payload.pickupAddress
     * @param {import('UI/shipment/models').InputAddress} payload.originAddress
     * @returns {void}
     */
    selectPickupToReuse: ({
      pickupOrderScheduleId,
      pickupSchedule,
      pickupCustomer,
      pickupAddress,
      originAddress
    }) =>
      send({
        type: EVENTS.SELECT_PICKUP_TO_REUSE,
        payload: {
          pickupOrderScheduleId,
          pickupSchedule,
          pickupCustomer,
          pickupAddress,
          originAddress
        }
      }),

    /**
     * @param {object} payload
     * @param {boolean} payload.choice - `true` if the user wants to continue and `false` otherwise.
     * @returns {void}
     */
    selectContinueShipment: ({ choice }) =>
      send({ type: EVENTS.SELECT_CONTINUE_SHIPMENT, payload: { choice } }),

    /**
     * @param {import('./types.d').ServiceType} payload - The service type (Loggi Fácil, Loggi Express, Indespacho)
     * @returns {void}
     */
    selectServiceType: payload =>
      send({ type: EVENTS.SELECT_SERVICE_TYPE, payload }),

    /**
     * @returns {void}
     */
    selectPixPayment: payload =>
      send({ type: EVENTS.SELECT_PIX_PAYMENT, payload }),

    /**
     * @returns {void}
     */
    balanceSuccessfullyAdded: payload =>
      send({ type: EVENTS.BALANCE_SUCCESSFULLY_ADDED, payload }),

    /**
     * @returns {void}
     */
    clickEditPickupAddress: () => send({ type: EVENTS.EDIT_PICKUP_ADDRESS }),

    /**
     * @returns {void}
     */
    noPickupSchedule: () => send({ type: EVENTS.NO_PICKUP_SCHEDULE }),

    /**
     * @returns {void}
     */
    clickEditDeliveryAddress: () =>
      send({ type: EVENTS.EDIT_DELIVERY_ADDRESS }),

    /**
     * @returns {void}
     */
    clickViewCoupons: () => send({ type: EVENTS.VIEW_COUPONS }),

    /**
     * @param {string} payload - The coupon code.
     * @returns {void}
     */
    setCoupon: payload => send({ type: EVENTS.SET_COUPON, payload }),

    /**
     * @returns {void}
     */
    invalidPickupSchedule: () => send({ type: EVENTS.INVALID_PICKUP_SCHEDULE }),

    /**
     * @param {import('./types.d').ShipmentOrder} payload - The order created.
     * @returns {void}
     */
    orderCreated: payload => send({ type: EVENTS.ORDER_CREATED, payload }),

    /**
     * @param {number} payload - The current balance.
     * @returns {void}
     */
    setCurrentBalance: payload =>
      send({ type: EVENTS.SET_CURRENT_BALANCE, payload })
  };
}

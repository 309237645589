import { ROUTES } from 'routes';
import { fillCompanyId } from 'utils';

/**
 * Returns the new app experience path
 *
 * @param {string} companyId
 * @returns {{ newAppExperiencePath: string, isLoading: boolean, isEnabled: boolean }}}
 */
export default function useNewAppExperiencePath(companyId) {
  return {
    newAppExperiencePath: fillCompanyId({
      route: ROUTES.newExperienceHome,
      companyId
    }),
    isLoading: false,
    isEnabled: true
  };
}

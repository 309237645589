// Temporary to allow both redirects (app.loggi and beyond.loggi)
// TODO: remove this method
const getRedirectURLSignInSignOut = isSignIn => {
  const newArchitecturePrefix = 'front-clientes-app-web';
  const isNewArchitecture = window.location.href.includes(
    newArchitecturePrefix
  );

  const isBeyond = window.location.href.includes(
    process.env.REACT_APP_AWS_REDIRECT_SIGN_IN_BEYOND
  );

  if (isNewArchitecture) {
    return `${window.location.origin}/`;
  }

  if (isBeyond) {
    return isSignIn
      ? process.env.REACT_APP_AWS_REDIRECT_SIGN_IN_BEYOND
      : process.env.REACT_APP_AWS_REDIRECT_SIGN_OUT_BEYOND;
  }

  return isSignIn
    ? process.env.REACT_APP_AWS_REDIRECT_SIGN_IN_APP
    : process.env.REACT_APP_AWS_REDIRECT_SIGN_OUT_APP;
};

// Remove the following envs:
// REACT_APP_AWS_REDIRECT_SIGN_IN_BEYOND
// REACT_APP_AWS_REDIRECT_SIGN_OUT_BEYOND
// Rename the following envs:
// REACT_APP_AWS_REDIRECT_SIGN_IN_APP -> REACT_APP_AWS_REDIRECT_SIGN_IN
// REACT_APP_AWS_REDIRECT_SIGN_OUT_APP -> REACT_APP_AWS_REDIRECT_SIGN_OUT

const awsmobile = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENT_POOL,
  aws_cognito_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_WEB_CLIENT,
  oauth: {
    domain: process.env.REACT_APP_AWS_COGNITO_DOMAINANME,
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin'
    ],
    redirectSignIn: getRedirectURLSignInSignOut(true),
    redirectSignOut: getRedirectURLSignInSignOut(false),
    responseType: 'code'
  },
  federationTarget: 'COGNITO_USER_POOLS'
};

export default awsmobile;

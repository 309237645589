import { withGroupedShipment } from 'UI/contexts/grouped-shipment/grouped-shipment-in-pickup.context';
import {
  ProtectFromAuthenticated,
  protect,
  withCRMIntegration
} from 'UI/decorators';
import { AccessDenied, AccountProblemPage, Tracking } from 'UI/pages';
import BudgetPageV2 from 'UI/shipment/pages/budget/budget.page';
import NewHomePage from 'UI/acquisition/pages/home/home.page';
import { withCouponOffer as withCouponOfferHome } from 'UI/acquisition/components/coupon-offer/coupon-offer';
import WalletPage from 'UI/pages/wallet/wallet.page';
import ImpersonatePage from 'UI/pages/impersonate/impersonate.page';
import SupportPage from 'UI/pages/support/support.page';
import ForgotPassword from 'containers/forgot-password/forgot-password.container';
import { SignInPageWithEventsObserver } from 'containers/sign-in/sign-in.component';
import SignUpCompany from 'containers/sign-up/sign-up-company/sign-up-company.container';
import SignUpUser from 'containers/sign-up/sign-up-user/sign-up-user.container';
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ShipmentRouter from 'UI/shipment/shipment-router';
import MyShipmentsPage from 'UI/shipments-v2/pages/my-shipments.page';
import {
  InitialPageWithEventsObserver,
  useInitialPageEnable
} from 'UI/pages/initial/initial.page';
import withShipmentMachine from 'UI/shipment/state-machine/decorator';
import DropOffAgenciesLocation from 'UI/pages/drop-off-agencies-location/drop-off-agencies-location.page';
import TrackerPage from 'UI/pages/tracker/tracker.page';
import LogoutPage from 'UI/shipment/pages/logout/logout.page';
import { CompanyIdGuard } from './guards/company-id.guard';
import * as ROUTES from './routes';

// This const is necessary to prevent re-render of the child
// Defining it inline inside the component prop does not work
const ProtectedSignIn = () => {
  return (
    <ProtectFromAuthenticated>
      <SignInPageWithEventsObserver />
    </ProtectFromAuthenticated>
  );
};

const ProtectedIntialPage = () => {
  return (
    <ProtectFromAuthenticated>
      <InitialPageWithEventsObserver />
    </ProtectFromAuthenticated>
  );
};

const AppRouter = ({ isImpersonation }) => {
  const isInitialPageEnable = useInitialPageEnable();

  return (
    <Switch>
      {!isImpersonation && (
        <Route path={ROUTES.impersonationForm} component={ImpersonatePage} />
      )}
      <Route path={ROUTES.accessDenied} component={AccessDenied} />
      <Route path={ROUTES.forgotPassword} component={ForgotPassword} />
      {isInitialPageEnable && (
        <Route path={ROUTES.initial} component={ProtectedIntialPage} />
      )}
      <Route path={ROUTES.login} component={ProtectedSignIn} />
      <Route
        path={ROUTES.occasionalTracking}
        component={protect(Tracking)}
        exact
      />
      <Route path={ROUTES.signUpCompany} component={SignUpCompany} />
      <Route path={ROUTES.signUpUser} component={SignUpUser} />
      <Route path={ROUTES.accountProblem} component={AccountProblemPage} />
      <Route path={ROUTES.support} component={protect(SupportPage)} exact />
      <Route
        component={protect(DropOffAgenciesLocation)}
        path={ROUTES.dropOffAgenciesLocation}
      />
      <Route
        path={ROUTES.newHome}
        component={protect(withCouponOfferHome(NewHomePage))}
      />
      <Route component={WalletPage} path={ROUTES.wallet} />
      <Route component={TrackerPage} path={ROUTES.tracker} />
      <Route component={LogoutPage} path={ROUTES.logout} />
      {/* V2 Routes */}
      <Route path={ROUTES.budgetV2} component={protect(BudgetPageV2)} exact />
      <Route path={ROUTES.shipmentV2} component={protect(ShipmentRouter)} />
      <Route path={ROUTES.myShipmentsV2} component={protect(MyShipmentsPage)} />
      {/* V2 Routes */}
      <Route path={ROUTES.companyIdGuard} component={protect(CompanyIdGuard)} />
      <Redirect to={ROUTES.companyIdGuard} />
    </Switch>
  );
};

AppRouter.propTypes = {
  isImpersonation: PropTypes.bool.isRequired
};

export default withShipmentMachine(
  withGroupedShipment(withCRMIntegration(AppRouter))
);

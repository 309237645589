import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS from 'crm/constants';
import { getPackageSizeDimensions } from 'UI/shipment/state-machine/utils';
import { getNewAppPreviousShipmentData, getServiceTypeValue } from 'crm/utils';

export default class PageCheckoutViewed extends CRMBaseEvent {
  constructor({
    originFullAddress,
    destinationFullAddress,
    price,
    slo,
    shipmentType,
    dimensions,
    goodsvalue,
    senderName,
    senderPhone,
    senderEmail,
    recipientName,
    recipiendPhone,
    recipientDocument,
    previousShipmentData
  }) {
    super();
    this.eventName = EVENTS.PAGE_CHECKOUT_VIEWED;
    this.payload = {
      origin_full_address: originFullAddress,
      destination_full_address: destinationFullAddress,
      price: price ? Number(price) : undefined,
      slo,
      shipment_type: shipmentType,
      dimensions,
      goodsvalue: goodsvalue ? Number(goodsvalue) : undefined,
      sender_name: senderName,
      sender_phone: senderPhone,
      sender_email: senderEmail,
      recipient_name: recipientName,
      recipient_phone: recipiendPhone,
      recipient_document: recipientDocument,
      ...previousShipmentData
    };
  }

  static fromShipmentContext({ context, quoting }) {
    return new PageCheckoutViewed({
      originFullAddress:
        context.pickupAddress?.description ||
        context.originAddress?.description(),
      destinationFullAddress:
        context.deliveryAddress?.description ||
        context.destinationAddress?.description(),
      price: quoting?.price,
      slo: quoting?.slo,
      shipmentType: getServiceTypeValue(context.serviceType),
      dimensions: getPackageSizeDimensions(context),
      goodsvalue: context.insurance,
      senderName: context.pickupCustomer?.name,
      senderPhone: context.pickupCustomer?.phone,
      senderEmail: context.pickupCustomer?.email,
      recipientName: context.deliveryCustomer?.name,
      recipiendPhone: context.deliveryCustomer?.phone,
      recipientDocument: context.deliveryCustomer?.cnpjCpf,
      previousShipmentData: getNewAppPreviousShipmentData()
    });
  }
}

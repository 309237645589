export const accessDenied = '/acesso-negado';
export const accountProblem = '/:companyId/problema-conta';
export const companyIdGuard = '/';
export const forgotPassword = '/esqueci-minha-senha';
export const impersonationForm = '/representar';
export const login = '/entrar';

export const occasionalTracking = '/rastreio/:companyId/:loggiKey';
export const dropOffAgenciesLocation =
  '/lista-agencias/:senderPostalCode/:pickupScheduledUpdatedDate';
export const tracker = '/rastreador';
export const signUp = '/cadastro';
export const signUpCompany = `${signUp}/conta`;
export const signUpUser = `${signUp}/usuario`;
export const support = '/suporte/:companyId/:loggiKey?';
export const initial = '/inicio';
export const newHome = '/:companyId/home';
export const wallet = '/:companyId/carteira';
export const myShipmentsV2 = '/v2/:companyId/meus-envios';

// Shipment v2
export const budgetV2 = '/v2/:companyId/orcamento';
export const shipmentV2 = '/v2/:companyId/envio';
export const shipmentPickupAddressV2 = `${shipmentV2}/retirada`;
export const shipmentDeliveryAddressV2 = `${shipmentV2}/entrega`;
export const shipmentRequirePackagingV2 = `${shipmentV2}/embalagem`;
export const newShipmentPackageSizeV2 = `${shipmentV2}/tamanho-embalagem`;
export const shipmentPackageDimensionsV2 = `${shipmentV2}/dimensoes-embalagem`;
export const shipmentPackagingInformationsV2 = `${shipmentV2}/informacoes-embalagem`;
export const shipmentPickupScheduleV2 = `${shipmentV2}/agendamento`;
export const shipmentPackageSizeV2 = `${shipmentV2}/tamanho`;
export const shipmentInsuranceValueV2 = `${shipmentV2}/seguro`;
export const shipmentListAgenciesV2 = `${shipmentV2}/lista-agencias`;
export const shipmentPickupCustomerV2 = `${shipmentV2}/remetente`;
export const shipmentDeliveryCustomerV2 = `${shipmentV2}/recebedor`;
export const shipmentWallet = `${shipmentV2}/carteira`;
export const shipmentAddWalletBalance = `${shipmentWallet}/adicionar-saldo`;
export const shipmentAddWalletBalanceOptions = `${shipmentAddWalletBalance}/opcoes`;
export const shipmentPreDefinedRechargeOptions = `${shipmentAddWalletBalance}/opcoes/recarga`;
export const shipmentInputRechargeValue = `${shipmentAddWalletBalance}/recarga/valor`;
export const shipmentAddWalletBalanceSuccess = `${shipmentAddWalletBalance}/sucesso`;
export const shipmentAddWalletBalanceError = `${shipmentAddWalletBalance}/erro`;
export const shipmentPayment = `${shipmentV2}/pagamento`;
export const shipmentPaymentCoupons = `${shipmentPayment}/cupons`;
export const shipmentPaymentPix = `${shipmentPayment}/pix`;
export const shipmentSummaryV2 = `${shipmentV2}/resumo`;
export const shipmentSummaryCouponsV2 = `${shipmentSummaryV2}/cupons`;
export const shipmentSummarySuccessV2 = `${shipmentSummaryV2}/sucesso`;
export const shipmentCreatedV2 = `${shipmentV2}/criado`;
export const shipmentIndispatchV2 = `${shipmentV2}/indespacho`;
export const shipmentIndispatchOnboardingV2 = `${shipmentIndispatchV2}/onboarding`;
export const shipmentIndispatchSummaryV2 = `${shipmentIndispatchV2}/resumo`;
export const shipmentIndispatchSuccessV2 = `${shipmentIndispatchV2}/sucesso`;
export const shipmentIndispatchServicesV2 = `${shipmentIndispatchV2}/servicos`;
export const shipmentDropoffOnboarding = `${shipmentV2}/dropoff-onboarding`;
export const shipmentPostalCodeAddresses = `${shipmentV2}/enderecos-cep`;
export const shipmentOriginAddress = `${shipmentV2}/endereco-origem`;
export const shipmentDestinationAddress = `${shipmentV2}/endereco-destino`;

// Clientes App
export const clientesAppHome = '/home/:companyId';
export const clientesAppPickup = '/coleta/:companyId';
export const clientesAppCheckout = '/envio/resumo/:companyId';
export const clientesAppCheckoutCorp = '/envio/resumo-entrega-local/:companyId';
export const clientesAppSuccess = '/envio/sucesso/:companyId';
export const clientesAppCreateCorpOrder =
  '/envio/criar/entrega-local/:companyId';
export const clientesAppPayDebt = '/valor-pendente/pagar/:companyId';
export const clientesAppPendingValue = '/valor-pendente/:companyId';

export const logout = '/logout';
// New experience
export const newExperienceHome = 'home/:companyId';

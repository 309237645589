const LOCAL_SERVICE_PAYLOAD = 'localServicePayload';

export const updateLocalServicePayload = payload => {
  const previousPayload = localStorage.getItem(LOCAL_SERVICE_PAYLOAD);
  if (previousPayload) {
    const parsedPayload = JSON.parse(previousPayload);

    const updatedPayload = {
      ...parsedPayload,
      ...payload
    };
    localStorage.setItem(LOCAL_SERVICE_PAYLOAD, JSON.stringify(updatedPayload));
  }
};

export default { updateLocalServicePayload };

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

import { useParams } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { loggiDesignSystem as theme } from '@loggi/front-design-system';
import { spacing } from '@loggi/front-design-tokens';

import { AmateurQuoting, Address } from 'models';
import {
  sendShipmentCreationFailedToCrm,
  sendShipmentSentEventsToCrm
} from 'crm/utils';
import {
  ShippingPayloadBuilder,
  getPackageSizeDimensions,
  getPickupQueryParamsFromClientesApp
} from 'UI/shipment/state-machine/utils';
import useServiceQuoting from 'UI/shipment/hooks/service-quoting';
import useCreateShipmentOrder from 'UI/shipment/hooks/create-shipment-order';
import {
  useShipmentContext,
  useShipmentDispatcher
} from 'UI/shipment/state-machine/context';
import { SIMPLE_PACKAGING } from 'UI/shipment/constants/packaging-service-type';
import CheckoutHeader from 'UI/shipment/components/checkout-header/header.component';
import useAddressCoverage from 'UI/shipment/hooks/address-coverage';

import { useFeatureSwitches } from 'hooks/feature-switches/feature-switches';
import { useWaitingPickup } from 'hooks/waiting-pickups/waiting-pickup.hook';
import { currencyFormatter, fillCompanyId } from 'utils';
import {
  clientesAppCheckout,
  clientesAppHome,
  clientesAppPickup
} from 'routes/routes';

import {
  buildMachinePayload,
  checkScheduleTimeAvailability,
  hasAddressLengthExceeded,
  saveShipmentOrderInfo
} from '../utils';
import AddressSection from './address-section/address-section.component';
import PackagingSection from './packaging-section/packaging-section.component';
import PickupSchedule from './pickup-schedule/pickup-schedule.component';
import Footer from '../wallet/footer/footer.component';
import { Loading } from './loading/loading.component';
import { PaymentError } from '../payment/payment-error/payment-error';
import { LOGGI_FREE } from '../payment/constants';

export default function CheckoutPage() {
  const { t } = useTranslation('ui');

  const LOGGI_COMPANY_ID = 3;

  const { companyId } = useParams();

  const isLoggiCompany = parseInt(companyId, 10) === LOGGI_COMPANY_ID;

  const [isCreateOrderPending, setIsCreateOrderPending] = useState(false);
  const [shippingApiError, setShippingApiError] = useState();
  const [scheduleAvailabilityError, setScheduleAvailabilityError] = useState();
  const [
    addressLengthExceededError,
    setAddressLengthExceededError
  ] = useState();

  const shipmentContext = useShipmentContext();
  const { context } = shipmentContext;
  const shipmentDispatcher = useShipmentDispatcher();
  const quoting = useServiceQuoting();
  const quotingFromClientesApp = context.quotingFromClientesApp
    ? new AmateurQuoting({
        amateur_service_type: context.quotingFromClientesApp.serviceType,
        slo_in_days: context.quotingFromClientesApp.slo,
        total_amount: context.quotingFromClientesApp.price,
        useFromMoneyToNumber: false
      })
    : null;

  const waitingPickup = useWaitingPickup(companyId);

  useFeatureSwitches({
    onSuccess(featureSwitches) {
      if (shipmentContext.isIdle) {
        shipmentDispatcher.init({
          companyId,
          featureSwitches,
          fromLoggiCheckout: true
        });
      }
    }
  });

  const showErrorScreen =
    !!shippingApiError ||
    !!scheduleAvailabilityError ||
    !!addressLengthExceededError;

  const getPackagingSectionTitle = () => {
    if (context.packagingService === SIMPLE_PACKAGING) {
      return t('checkout.packagingType.loggiPackaging');
    }

    return t('checkout.packagingType.selfPackaging');
  };

  const getOriginAddressSubtitle = () => {
    let originSubtitle = t('checkout.origin.nationalEconomic');

    if (AmateurQuoting.isDropoff(context.serviceType)) {
      originSubtitle = t('checkout.origin.dropoffSubtitle');
    }

    if (AmateurQuoting.isIndespacho({ serviceType: context.serviceType })) {
      originSubtitle = t('checkout.origin.indispatchSubtitle');
    }

    return { main: originSubtitle };
  };

  const getDestinationAddressSubtitle = () => ({
    main: t('checkout.destination.subtitle', {
      count: quotingFromClientesApp?.slo || quoting?.slo || 1
    }),
    secondary: t('checkout.destination.secondarySubtitle')
  });

  const createShipmentOrder = useCreateShipmentOrder({
    onMutate: sendShipmentSentEventsToCrm,
    onError: sendShipmentCreationFailedToCrm
  });

  const pickupAddressCoverage = useAddressCoverage({
    address: context.pickupAddress || context.originAddress,
    companyId
  });

  const handleException = (ex, machinePayload) => {
    Sentry.captureException(ex);
    Sentry.setContext('payload', machinePayload);

    setShippingApiError(ex ?? {});
  };

  const createOrder = async () => {
    if (isCreateOrderPending) return;

    const payloadBuilder = new ShippingPayloadBuilder()
      .setShipmentContext(context)
      .setQuoting(quotingFromClientesApp || quoting);

    const paymentMethod = null;
    const machinePayload = buildMachinePayload({
      paymentMethod,
      payloadBuilder,
      context,
      quoting: quotingFromClientesApp || quoting
    });

    try {
      setIsCreateOrderPending(true);

      const shipmentOrder = await createShipmentOrder.mutateAsync({
        companyId,
        payload: machinePayload,
        context: { ...context }
      });

      if (
        !AmateurQuoting.isIndespacho({
          serviceType: context.serviceType
        })
      ) {
        waitingPickup.setWaitingPickup(shipmentOrder?.pickupOrderSchedule);
      }

      saveShipmentOrderInfo(shipmentOrder);

      shipmentDispatcher.orderCreated(shipmentOrder);

      setIsCreateOrderPending(Boolean(context.quotingFromClientesApp));
    } catch (ex) {
      handleException(ex, machinePayload);
      setIsCreateOrderPending(false);
    }
  };

  const onSubmitHandler = () => {
    if (!isLoggiCompany) {
      return shipmentDispatcher.continue();
    }
    if (
      AmateurQuoting.isBeyond(context.serviceType) &&
      !checkScheduleTimeAvailability(pickupAddressCoverage, context)
    ) {
      return setScheduleAvailabilityError({
        status: 'scheduleAvailabilityError'
      });
    }
    if (hasAddressLengthExceeded(context)) {
      return setAddressLengthExceededError({
        status: 'addressLengthExceededError'
      });
    }

    return createOrder();
  };

  const goBack = () => {
    if (!context.quotingFromClientesApp) {
      shipmentDispatcher.back();
    } else {
      const appBaseURL = window.location.origin;

      if (AmateurQuoting.isBeyond(context.serviceType)) {
        // goes back to pickup page with the same query params
        const clientesAppPickupBaseURL = fillCompanyId({
          companyId,
          route: clientesAppPickup
        });
        window.location.href = `${appBaseURL}${clientesAppPickupBaseURL}${getPickupQueryParamsFromClientesApp()}`;
      } else {
        // goes back to checkout page
        const clientesAppCheckoutURL = fillCompanyId({
          companyId,
          route: clientesAppCheckout
        });

        window.location.href = `${appBaseURL}${clientesAppCheckoutURL}`;
      }
    }
  };

  const handleCancelPayment = () => {
    if (context.quotingFromClientesApp) {
      const clientesAppHomeURL = fillCompanyId({
        companyId,
        route: clientesAppHome
      });
      window.location.href = `${window.location.origin}${clientesAppHomeURL}`;
    } else {
      shipmentDispatcher.clear();
    }
  };

  return (
    <>
      {!shipmentContext.isIdle && (
        <ThemeProvider theme={theme}>
          {showErrorScreen && (
            <PaymentError
              error={
                shippingApiError ||
                scheduleAvailabilityError ||
                addressLengthExceededError
              }
              amount={quotingFromClientesApp?.price || quoting?.price}
              paymentMethod={LOGGI_FREE}
              onRetry={createOrder}
              onCancel={handleCancelPayment}
            />
          )}
          {!showErrorScreen && (
            <Box minHeight="100%">
              <Stack pb={spacing.stack.xxxlarge}>
                <CheckoutHeader title={t('checkout.title')} goBack={goBack} />
                {context.pickupSchedule && (
                  <PickupSchedule
                    title={t('checkout.pickupSchedule.title')}
                    pickupSchedule={context.pickupSchedule}
                  />
                )}
                <AddressSection
                  title={t('checkout.origin.title')}
                  address={
                    context.pickupAddress ||
                    new Address(context.originAddress?.toStructured())
                  }
                  subtitle={getOriginAddressSubtitle()}
                  onClick={() => {
                    shipmentDispatcher.clickEditPickupAddress();
                  }}
                  enableEdit={!context.quotingFromClientesApp}
                />
                <AddressSection
                  title={t('checkout.destination.title')}
                  address={
                    context.deliveryAddress ||
                    new Address(context.destinationAddress?.toStructured())
                  }
                  subtitle={getDestinationAddressSubtitle()}
                  onClick={() => {
                    shipmentDispatcher.clickEditDeliveryAddress();
                  }}
                  enableEdit={!context.quotingFromClientesApp}
                />
                <PackagingSection
                  title={getPackagingSectionTitle()}
                  dimensions={getPackageSizeDimensions(context)}
                />
              </Stack>
              <Footer>
                <Footer.PrimaryAction
                  title={
                    isLoggiCompany
                      ? t('checkout.loggiCompanyPaymentButton')
                      : t('checkout.paymentButton', {
                          amount: currencyFormatter(quoting?.price)
                        })
                  }
                  onClick={onSubmitHandler}
                />
              </Footer>
            </Box>
          )}
        </ThemeProvider>
      )}
      <Loading isVisible={isCreateOrderPending} />
    </>
  );
}

import React, { useEffect } from 'react';
import { useShipmentDispatcher } from 'UI/shipment/state-machine/context';
import { useCurrentUser } from 'hooks/current-user/use-current-user.hook';

import AccountLoggedOut from 'crm/entities/events/account-logged-out/account-logged-out';
import { useAmplifyAuth } from '@loggi/authentication-lib';
import { Loader } from 'UI/components';
import { logoutInsiderUser } from 'mobile';

export default function LogoutPage() {
  const { signOut } = useAmplifyAuth();
  const { user } = useCurrentUser();
  const shipmentDispatcher = useShipmentDispatcher();

  const handleSignOut = async () => {
    window.localStorage.clear();
    shipmentDispatcher.exit();
    new AccountLoggedOut({ email: user.email }).sendToCrm();
    logoutInsiderUser();
    signOut();
  };

  useEffect(() => {
    if (user) {
      handleSignOut();
    }
    // eslint-disable-next-line
  }, [user]);

  return <Loader />;
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Alert, Stack, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { loggiDesignSystem as theme } from '@loggi/front-design-system';
import { colors, spacing } from '@loggi/front-design-tokens';
import {
  useShipmentContext,
  useShipmentDispatcher
} from 'UI/shipment/state-machine/context';
import useWalletBalance from 'hooks/wallet-balance/wallet-balance.hook';
import { useFeatureSwitches } from 'hooks/feature-switches/feature-switches';
import { clientesAppHome } from 'routes/routes';
import { fillCompanyId } from 'utils';

import CheckoutHeader from 'UI/shipment/components/checkout-header/header.component';
import { FROM_CLIENTES_APP_WEB } from 'UI/shipment/state-machine/constants';
import OptionItem from './option-item/option-item';
import { CREDIT_CARD, PIX } from '../../payment/constants';

export default function AddWalletBalanceOptionsPage() {
  const { t } = useTranslation('ui');
  const { companyId } = useParams();

  const shipmentContext = useShipmentContext();
  const shipmentDispatcher = useShipmentDispatcher();

  const balanceQuery = useWalletBalance({ companyId });

  const handleContinueClick = paymentMethod => {
    shipmentDispatcher.setCurrentBalance(balanceQuery?.data?.balance);

    shipmentDispatcher.continue(paymentMethod);
  };

  useFeatureSwitches({
    onSuccess(featureSwitches) {
      if (shipmentContext.isIdle) {
        shipmentDispatcher.init({
          companyId,
          featureSwitches,
          fromAddBalanceOptions: true
        });
      }
    }
  });

  const goBack = () => {
    if (shipmentContext.context.addBalanceFrom === FROM_CLIENTES_APP_WEB) {
      const clientesAppHomeURL = fillCompanyId({
        companyId,
        route: clientesAppHome
      });
      window.location.href = `${window.location.origin}${clientesAppHomeURL}`;
    } else {
      shipmentDispatcher.back();
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Stack>
        <Stack pb={spacing.inset.medium}>
          <CheckoutHeader
            title={t('wallet.addBalance.options.title')}
            goBack={goBack}
          />
        </Stack>
        <OptionItem
          title={t('wallet.addBalance.options.pix.title')}
          description={t('wallet.addBalance.options.pix.description')}
          onClick={() => handleContinueClick(PIX)}
        />
        <OptionItem
          title={t('wallet.addBalance.options.creditCard.title')}
          description={t('wallet.addBalance.options.creditCard.description')}
          onClick={() => handleContinueClick(CREDIT_CARD)}
        />
        <Stack width="100%" alignItems="center" p={spacing.inline.xsmall}>
          <Alert
            icon={false}
            size="small"
            type="alert"
            sx={{
              '.MuiAlert-message': {
                display: 'block'
              }
            }}
          >
            <Typography
              variant="bodyTextMedium"
              color={colors.neutrals['on-surface']}
            >
              {t('wallet.addBalance.options.footerSubtitle')}
            </Typography>
          </Alert>
        </Stack>
      </Stack>
    </ThemeProvider>
  );
}

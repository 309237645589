import {
  Box,
  Divider,
  SwipeableDrawer,
  ToggleButton,
  Typography
} from '@mui/material';
import { ICON_VARIANTS } from 'constants/index';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { blue, grey, root } from 'theme/colors';
import { Button, SvgIcon } from 'UI/components';
import AlertDialog from 'UI/components/alert-dialog/redirect-alert/alert-dialog.component';
import AccountDeleteAlert from 'UI/components/alert-dialog/delete-alert/alert-dialog.component';
import { useCorp } from 'UI/contexts';
import { supportPagePath } from 'UI/pages/support/support.page';
import {
  myShipmentsV2,
  newHome,
  companyIdGuard,
  tracker as trackerRoute
} from 'routes/routes';
import AccountDeleted from 'crm/entities/events/account-deleted/account-deleted';
import MenuOptionSelected from 'crm/entities/events/menu-option-selected/menu-option-selected';
import { BUTTON_OPTION } from 'crm/constants';
import useFeatureSwitch from 'hooks/feature-switch/feature-switch.hook';
import { useCurrentUser } from 'hooks/current-user/use-current-user.hook';
import { useShipmentDispatcher } from 'UI/shipment/state-machine/context';

const TEMPORARY_FAKE_LOGGI_KEY = '000000000000000000';
const VERSION = process.env.REACT_APP_VERSION;

const BeyondVersion = () => {
  const allowedToView = useFeatureSwitch('allowed_to_view_debug_info', []);
  const { user } = useCurrentUser();
  const isVisible = !!user && allowedToView.data.includes(user.email);

  if (!isVisible) return null;

  return (
    <div>
      <span>
        <small>
          <code>{VERSION}</code>
        </small>
      </span>
    </div>
  );
};

/**
 * @param {object} props
 * @param {'primary' | 'white' | 'dark'} props.variant
 * @returns
 */
const UserProfileButton = ({ onSignOut, user, variant }) => {
  const { t } = useTranslation('ui');
  const { companyId } = useParams();
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isAlertDialogOpen, setIsAlertDialogOpen] = useState(false);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const { openCorp } = useCorp();
  const history = useHistory();
  const route = useRouteMatch();
  const newHomePath = newHome.replace(':companyId', companyId);
  const isAtShipmentsPage =
    route.url === myShipmentsV2.replace(':companyId', companyId);
  const isAtNewHomePage = route.url === newHomePath;
  const showNewShipmentButton = !isAtNewHomePage;

  const { data: isInternalTrackerPageEnabled } = useFeatureSwitch(
    'internal_tracker_page_enabled',
    false
  );

  const supportPage = supportPagePath({
    companyId,
    loggiKey: TEMPORARY_FAKE_LOGGI_KEY,
    defaultReason: 'CONTACT_REASON_DELETE_ACCOUNT'
  });

  const dispatcher = useShipmentDispatcher();

  const shipmentsUrl = () => {
    return history.push(myShipmentsV2.replace(':companyId', companyId));
  };

  const goToNewHomePage = () => {
    return history.push(companyIdGuard);
  };

  const openDialog = () => {
    setIsDialogVisible(true);
  };

  const sendMenuOptionSelectedEvent = buttonOption => {
    new MenuOptionSelected({ buttonOption }).sendToCrm();
  };

  const handleDeletionContinue = () => {
    new AccountDeleted({ email: user.email }).sendToCrm();
  };

  const handleNewShipmentOptionClick = () => {
    sendMenuOptionSelectedEvent(BUTTON_OPTION.NEW_SHIPMENT);
    return goToNewHomePage();
  };

  const handleCorpOptionClick = () => {
    sendMenuOptionSelectedEvent(BUTTON_OPTION.EXPRESSO);
    openCorp();
  };

  const handleShipmentsOptionClick = () => {
    dispatcher.exit();
    sendMenuOptionSelectedEvent(BUTTON_OPTION.MY_SHIPMENTS);
    shipmentsUrl();
  };

  const handleTrackingOptionClick = () => {
    sendMenuOptionSelectedEvent(BUTTON_OPTION.TRACKING);

    if (isInternalTrackerPageEnabled) {
      history.push(trackerRoute);
      return;
    }

    openDialog();
  };

  const handleDeleteAccountOptionClick = () => {
    sendMenuOptionSelectedEvent(BUTTON_OPTION.DELETE_ACCOUNT);
    setIsAlertDialogOpen(true);
  };

  return (
    <>
      <ToggleButton
        id="user_profile_menu_button"
        onChange={() => setIsDrawerVisible(true)}
        selected={isDrawerVisible}
        size="small"
        style={{ border: 'none', padding: 0 }}
        data-testid="user-profile-button"
        value
      >
        <SvgIcon color={ICON_VARIANTS[variant].color} icon="menu" />
      </ToggleButton>
      <SwipeableDrawer
        data-testid="user-menu-drawer"
        anchor="bottom"
        disableSwipeToOpen
        onClose={() => setIsDrawerVisible(false)}
        onOpen={noop}
        open={isDrawerVisible}
        PaperProps={{
          style: {
            borderTopRightRadius: '12px',
            borderTopLeftRadius: '12px'
          }
        }}
      >
        <Box
          alignSelf="center"
          borderRadius="100px"
          height="4px"
          mt={3}
          style={{ backgroundColor: grey[100] }}
          width="40px"
        />
        <Box mt={3} py={2} px={3}>
          <Box color={root[900]} display="flex" flexDirection="column">
            <Typography variant="subtitle1">{user.name}</Typography>
            <Box alignItems="center" display="flex" mt={2}>
              <SvgIcon icon="userProfileSquare" />
              <Box ml={1}>
                <Typography variant="body2">{user.email}</Typography>
                <BeyondVersion />
              </Box>
            </Box>
          </Box>
          <Box mt={3} mb={1}>
            <Divider style={{ backgroundColor: grey[50] }} />
          </Box>
          {showNewShipmentButton && (
            <>
              <Button
                id="cta_novo_envio"
                alignment="left"
                fontWeight={400}
                fullWidth
                icon="truckFast"
                label={t('userProfile.newShipment')}
                onClick={handleNewShipmentOptionClick}
                size="lg"
                textColor={root[900]}
                variant="text"
              />
              <Box my={2}>
                <Divider style={{ backgroundColor: grey[50] }} />
              </Box>
            </>
          )}
          <Button
            id="cta_realizar_envio_corp"
            alignment="left"
            fontWeight={400}
            fullWidth
            icon="motorcycle"
            label={t('userProfile.corp')}
            onClick={handleCorpOptionClick}
            size="lg"
            textColor={root[900]}
            variant="text"
          />
          <Box my={2}>
            <Divider style={{ backgroundColor: grey[50] }} />
          </Box>
          {!isAtShipmentsPage && (
            <>
              <Button
                id="cta_seus_envios_menu_home"
                alignment="left"
                fontWeight={400}
                fullWidth
                icon="package"
                label={t('userProfile.shipments')}
                onClick={handleShipmentsOptionClick}
                size="lg"
                textColor={root[900]}
                variant="text"
              />
              <Box my={2}>
                <Divider style={{ backgroundColor: grey[50] }} />
              </Box>
            </>
          )}
          <Button
            id="cta_rastrear_pacotes_menu_home"
            alignment="left"
            fontWeight={400}
            fullWidth
            icon="search"
            label={t('userProfile.trackPackages')}
            onClick={handleTrackingOptionClick}
            size="lg"
            textColor={root[900]}
            variant="text"
          />
          <Box my={2}>
            <Divider style={{ backgroundColor: grey[50] }} />
          </Box>
          <Button
            alignment="left"
            fontWeight={400}
            fullWidth
            icon="deleteTrash"
            label={t('userProfile.deleteAccount')}
            onClick={handleDeleteAccountOptionClick}
            size="lg"
            textColor={root[900]}
            variant="text"
          />
          <Box my={2}>
            <Divider style={{ backgroundColor: grey[50] }} />
          </Box>
          <Button
            alignment="left"
            fontWeight={400}
            fullWidth
            icon="exit"
            label={t('userProfile.signOut')}
            onClick={onSignOut}
            size="lg"
            textColor={blue[500]}
            variant="text"
          />
        </Box>
        <AccountDeleteAlert
          onContinue={handleDeletionContinue}
          onClose={() => setIsAlertDialogOpen(false)}
          open={isAlertDialogOpen}
          title={t('userProfile.dialogToDeleteAccount.title')}
          url={supportPage}
        />
      </SwipeableDrawer>
      <AlertDialog
        onClose={() => setIsDialogVisible(false)}
        open={isDialogVisible}
        title={t('trackingButton.dialog.title')}
        url={process.env.REACT_APP_TRACKER_URL}
      />
    </>
  );
};

UserProfileButton.defaultProps = {
  variant: 'primary'
};

UserProfileButton.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired,
  onSignOut: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(Object.keys(ICON_VARIANTS))
};

export default UserProfileButton;

/* eslint-disable import/prefer-default-export */
import { merge, pick } from 'lodash';
import { AmateurQuoting } from 'models';
import { assign, createMachine } from 'xstate';
import {
  NOT_INFORMED,
  NO_PACKAGING,
  SIMPLE_PACKAGING
} from '../constants/packaging-service-type';
import {
  EVENTS,
  FROM_CLIENTES_APP_WEB,
  INITIAL_CONTEXT,
  PERSISTENCE_FIELDS,
  STATES
} from './constants';
import {
  buildAutoRecoveredContext,
  buildContextFromClientesApp,
  buildDebtPayment,
  buildPendingContext,
  isGroupingShipmentInPickup,
  isIndespacho,
  isLoggiFacil,
  isUsingInputAddress,
  redirectToAddressState,
  setBalanceSuccessullyAdded,
  setBalanceSuccessullyAddedFromApi
} from './utils';

/**
 * Creates the shipment machine representation
 * @param {Partial<typeof INITIAL_CONTEXT>} context
 * @returns {import('xstate').StateMachine<typeof INITIAL_CONTEXT, typeof STATES, keyof typeof EVENTS>}
 */
export function createShipmentMachine(
  context = INITIAL_CONTEXT,
  initialState = STATES.IDLE
) {
  return createMachine({
    id: 'shipment-machine',
    // read more: https://xstate.js.org/docs/guides/actions.html#actions
    predictableActionArguments: true,
    context: merge(INITIAL_CONTEXT, context),
    initial: initialState,
    states: {
      [STATES.IDLE]: {
        /**
         * State that represents a machine waiting to start
         */
        entry: ['resetPersistedContext', 'resetContext'],
        on: {
          [EVENTS.INIT]: [
            {
              target: STATES.PIX_PAYMENT,
              cond: (_, ev) => ev.payload.hasCurrentPixPaymentSession !== null,
              actions: [
                assign((_, { payload }) => ({
                  companyId: payload.companyId,
                  featureSwitches: payload.featureSwitches,
                  ...buildContextFromClientesApp(),
                  ...buildDebtPayment(),
                  ...buildAutoRecoveredContext(payload.storedContext),
                  ...pick(
                    buildPendingContext(payload.storedContext),
                    PERSISTENCE_FIELDS
                  ),
                  pendingContext: null
                }))
              ]
            },
            {
              target: STATES.WALLET,
              cond: (_, ev) => ev.payload.fromWallet === true,
              actions: [
                assign((_, { payload }) => ({
                  companyId: payload.companyId,
                  featureSwitches: payload.featureSwitches,
                  addBalanceFrom: FROM_CLIENTES_APP_WEB,
                  pendingContext: buildPendingContext(payload.storedContext),
                  ...buildAutoRecoveredContext(payload.storedContext)
                }))
              ]
            },
            {
              target: STATES.ADD_WALLET_BALANCE_OPTIONS,
              cond: (_, ev) => ev.payload.fromAddBalanceOptions === true,
              actions: [
                assign((_, { payload }) => ({
                  companyId: payload.companyId,
                  featureSwitches: payload.featureSwitches,
                  pendingContext: buildPendingContext(payload.storedContext),
                  addBalanceFrom: FROM_CLIENTES_APP_WEB
                }))
              ]
            },
            {
              target: STATES.PAYMENT,
              cond: (_, ev) => ev.payload.fromPayment === true,
              actions: [
                assign((_, { payload }) => ({
                  companyId: payload.companyId,
                  featureSwitches: payload.featureSwitches,
                  ...buildContextFromClientesApp(),
                  ...buildDebtPayment()
                }))
              ]
            },
            {
              target: STATES.SUMMARY,
              cond: (_, ev) => ev.payload.fromLoggiCheckout === true,
              actions: [
                assign((_, { payload }) => ({
                  companyId: payload.companyId,
                  featureSwitches: payload.featureSwitches,
                  ...buildContextFromClientesApp(),
                  ...buildDebtPayment()
                }))
              ]
            },
            {
              target: STATES.HOME,
              cond: (_, ev) => ev.payload.newHomeIsEnabled === true,
              actions: [
                assign((_, { payload }) => ({
                  companyId: payload.companyId,
                  featureSwitches: payload.featureSwitches,
                  pendingContext: buildPendingContext(payload.storedContext),
                  ...buildAutoRecoveredContext(payload.storedContext)
                }))
              ]
            },
            {
              target: STATES.BUDGET,
              actions: [
                assign((_, { payload }) => ({
                  companyId: payload.companyId,
                  featureSwitches: payload.featureSwitches,
                  pendingContext: buildPendingContext(payload.storedContext),
                  ...buildAutoRecoveredContext(payload.storedContext)
                }))
              ]
            }
          ]
        }
      },
      [STATES.BUDGET]: {
        /**
         * State that represents the budget page
         */
        entry: [
          assign({
            serviceType: INITIAL_CONTEXT.serviceType,
            packagingService: INITIAL_CONTEXT.packagingService,
            packageSize: INITIAL_CONTEXT.packageSize,
            packageDimensions: INITIAL_CONTEXT.packageDimensions,
            insurance: INITIAL_CONTEXT.insurance
          }),
          'persist'
        ],
        on: {
          [EVENTS.EXIT]: {
            /**
             * Event emitted when the user log out. It causes the machine to
             * clear the persisted state and transition to the idle state.
             */
            target: STATES.IDLE,
            actions: ['purgePersistedContext', 'purgeContext']
          },
          [EVENTS.EDIT_PICKUP_ADDRESS]: {
            target: STATES.PICKUP_ADDRESS,
            actions: ['sendClickPickupAddress']
          },
          [EVENTS.CLICK_PICKUP_ADDRESS]: [
            {
              cond: ctx => ctx.originAddress !== null,
              target: STATES.POSTAL_CODE_ADDRESSES,
              actions: [
                assign({
                  fromTheHome: false
                }),
                'sendClickPickupAddress'
              ]
            },
            {
              target: STATES.PICKUP_ADDRESS,
              actions: [
                assign({
                  fromTheHome: false
                }),
                'sendClickPickupAddress'
              ]
            }
          ],
          [EVENTS.CLICK_DELIVERY_ADDRESS]: [
            {
              cond: ctx => ctx.destinationAddress !== null,
              target: STATES.POSTAL_CODE_ADDRESSES,
              actions: [
                assign({
                  fromTheHome: false
                }),
                'sendClickDeliveryAddress'
              ]
            },
            {
              target: STATES.DELIVERY_ADDRESS,
              actions: [
                assign({
                  fromTheHome: false
                }),
                'sendClickDeliveryAddress'
              ]
            }
          ],
          [EVENTS.SELECT_PACKAGE_SIZE]: {
            actions: [
              assign({
                packageSize: (_, ev) => ev.payload
              })
            ]
          },
          [EVENTS.SELECT_SERVICE_TYPE]: [
            {
              cond: (_, ev) => isLoggiFacil(ev.payload),
              target: STATES.REQUIRE_PACKAGING,
              actions: [
                assign({ serviceType: (_, ev) => ev.payload }),
                'sendSelectedServiceType'
              ]
            },
            {
              cond: (_, ev) =>
                ev.payload === 'AMATEUR_SERVICE_TYPE_LOCAL_EXPRESS',
              target: STATES.PICKUP_CUSTOMER,
              actions: [
                assign({
                  serviceType: (_, event) => event.payload
                }),
                'sendSelectedServiceType'
              ]
            },
            {
              cond: (_, ev) =>
                AmateurQuoting.isIndespacho({
                  serviceType: ev.payload
                }),
              target: STATES.PACKAGE_DIMENSIONS,
              actions: [
                assign({
                  serviceType: (_, event) => event.payload,
                  packagingService: NO_PACKAGING
                }),
                'sendSelectedServiceType'
              ]
            },
            {
              cond: (_, ev) => AmateurQuoting.isDropoff(ev.payload),
              target: STATES.PACKAGE_DIMENSIONS,
              actions: [
                assign({
                  serviceType: (_, event) => event.payload,
                  packagingService: NO_PACKAGING
                }),
                'sendSelectedServiceType'
              ]
            }
          ],
          [EVENTS.SELECT_PICKUP_TO_REUSE]: [
            {
              target: STATES.POSTAL_CODE_ADDRESSES,
              cond: (_, ev) => !!ev.payload.originAddress,
              actions: [
                assign({
                  serviceType: 'AMATEUR_SERVICE_TYPE_NATIONAL_ECONOMIC',
                  pickupOrderScheduleId: (_, ev) =>
                    ev.payload.pickupOrderScheduleId,
                  pickupSchedule: (_, ev) => ev.payload.pickupSchedule,
                  pickupCustomer: (_, ev) => ev.payload.pickupCustomer,
                  originAddress: (_, ev) => ev.payload.originAddress,
                  fromPickupReuse: true
                })
              ]
            },
            {
              target: STATES.DELIVERY_ADDRESS,
              actions: [
                assign({
                  serviceType: 'AMATEUR_SERVICE_TYPE_NATIONAL_ECONOMIC',
                  pickupOrderScheduleId: (_, ev) =>
                    ev.payload.pickupOrderScheduleId,
                  pickupSchedule: (_, ev) => ev.payload.pickupSchedule,
                  pickupCustomer: (_, ev) => ev.payload.pickupCustomer,
                  pickupAddress: (_, ev) => ev.payload.pickupAddress
                })
              ]
            }
          ],
          [EVENTS.SELECT_CONTINUE_SHIPMENT]: [
            {
              cond: (_, ev) => ev.payload.choice === true,
              target: STATES.BUDGET,
              actions: assign(ctx => ({
                ...pick(ctx.pendingContext, PERSISTENCE_FIELDS),
                pendingContext: null
              }))
            },
            {
              cond: (_, ev) => ev.payload.choice === false,
              target: STATES.BUDGET,
              actions: assign({
                pendingContext: null
              })
            }
          ],
          [EVENTS.BACK]: {
            target: STATES.HOME,
            actions: [assign({ deliveryAddress: null })]
          }
        }
      },
      [STATES.HOME]: {
        /**
         * State that represents the home page
         */
        entry: [
          assign({
            serviceType: INITIAL_CONTEXT.serviceType,
            packageSize: INITIAL_CONTEXT.packageSize,
            packageDimensions: INITIAL_CONTEXT.packageDimensions,
            insurance: INITIAL_CONTEXT.insurance
          }),
          'persist'
        ],
        on: {
          [EVENTS.EXIT]: {
            /**
             * Event emitted when the user log out. It causes the machine to
             * clear the persisted state and transition to the idle state.
             */
            target: STATES.IDLE,
            actions: ['purgePersistedContext', 'purgeContext']
          },
          [EVENTS.CLICK_WALLET_CARD]: [
            {
              target: STATES.WALLET
            }
          ],
          [EVENTS.CLICK_PICKUP_ADDRESS]: [
            {
              cond: ctx => ctx.originAddress !== null,
              target: STATES.POSTAL_CODE_ADDRESSES,
              actions: [
                assign({
                  fromTheHome: true
                }),
                'sendClickPickupAddress'
              ]
            },
            {
              target: STATES.PICKUP_ADDRESS,
              actions: [
                assign({
                  fromTheHome: true
                }),
                'sendClickPickupAddress'
              ]
            }
          ],
          [EVENTS.CLICK_DELIVERY_ADDRESS]: [
            {
              cond: ctx => !!ctx.destinationAddress || !!ctx.originAddress,
              target: STATES.POSTAL_CODE_ADDRESSES,
              actions: [
                assign({
                  fromTheHome: true
                }),
                'sendClickDeliveryAddress'
              ]
            },
            {
              target: STATES.DELIVERY_ADDRESS,
              actions: [
                assign({
                  fromTheHome: true
                }),
                'sendClickDeliveryAddress'
              ]
            }
          ],
          [EVENTS.CLICK_ADD_WALLET_BALANCE]: {
            target: STATES.ADD_WALLET_BALANCE_OPTIONS,
            actions: [
              assign({
                addBalanceFrom: STATES.HOME
              })
            ]
          },
          [EVENTS.SELECT_PICKUP_TO_REUSE]: [
            {
              target: STATES.POSTAL_CODE_ADDRESSES,
              cond: (_, ev) => !!ev.payload.originAddress,
              actions: [
                assign({
                  serviceType: 'AMATEUR_SERVICE_TYPE_NATIONAL_ECONOMIC',
                  pickupOrderScheduleId: (_, ev) =>
                    ev.payload.pickupOrderScheduleId,
                  pickupSchedule: (_, ev) => ev.payload.pickupSchedule,
                  pickupCustomer: (_, ev) => ev.payload.pickupCustomer,
                  originAddress: (_, ev) => ev.payload.originAddress,
                  fromTheHome: true,
                  fromPickupReuse: true
                })
              ]
            },
            {
              target: STATES.DELIVERY_ADDRESS,
              actions: [
                assign({
                  serviceType: 'AMATEUR_SERVICE_TYPE_NATIONAL_ECONOMIC',
                  pickupOrderScheduleId: (_, ev) =>
                    ev.payload.pickupOrderScheduleId,
                  pickupSchedule: (_, ev) => ev.payload.pickupSchedule,
                  pickupCustomer: (_, ev) => ev.payload.pickupCustomer,
                  pickupAddress: (_, ev) => ev.payload.pickupAddress,
                  fromTheHome: true
                })
              ]
            }
          ],
          [EVENTS.SELECT_CONTINUE_SHIPMENT]: [
            {
              cond: (_, ev) => ev.payload.choice === true,
              target: STATES.BUDGET,
              actions: assign(ctx => ({
                ...pick(ctx.pendingContext, PERSISTENCE_FIELDS),
                pendingContext: null
              }))
            },
            {
              cond: (_, ev) => ev.payload.choice === false,
              target: STATES.HOME,
              actions: assign({
                pendingContext: null
              })
            }
          ]
        }
      },
      [STATES.WALLET]: {
        on: {
          [EVENTS.CLICK_ADD_WALLET_BALANCE]: {
            target: STATES.ADD_WALLET_BALANCE_OPTIONS,
            actions: [
              assign({
                addBalanceFrom: STATES.WALLET
              })
            ]
          },
          [EVENTS.BACK]: {
            target: STATES.HOME
          }
        }
      },
      [STATES.POSTAL_CODE_ADDRESSES]: {
        on: {
          [EVENTS.SET_ORIGIN_ADDRESS]: {
            target: STATES.POSTAL_CODE_ADDRESSES,
            actions: assign({
              originAddress: (_, ev) => ev.payload
            })
          },
          [EVENTS.SET_DELIVERY_ADDRESS]: {
            target: STATES.POSTAL_CODE_ADDRESSES,
            actions: assign({
              destinationAddress: (_, ev) => ev.payload
            })
          },
          [EVENTS.CONTINUE]: [
            {
              cond: ctx => isGroupingShipmentInPickup(ctx),
              target: STATES.REQUIRE_PACKAGING,
              actions: assign({
                pickupAddress: INITIAL_CONTEXT.pickupAddress,
                deliveryAddress: INITIAL_CONTEXT.deliveryAddress
              })
            },
            {
              target: STATES.BUDGET,
              actions: assign({
                pickupAddress: INITIAL_CONTEXT.pickupAddress,
                deliveryAddress: INITIAL_CONTEXT.deliveryAddress
              })
            }
          ],
          [EVENTS.BACK]: [
            {
              cond: ctx => ctx.fromTheHome,
              target: STATES.HOME,
              actions: assign({
                pickupOrderScheduleId: INITIAL_CONTEXT.pickupOrderScheduleId
              })
            },
            {
              target: STATES.BUDGET,
              actions: assign({
                pickupOrderScheduleId: INITIAL_CONTEXT.pickupOrderScheduleId
              })
            }
          ],
          [EVENTS.CLICK_QUOTATION_WITH_ADDRESS]: [
            {
              cond: ctx => ctx.fromPickupReuse === true,
              target: STATES.DELIVERY_ADDRESS,
              actions: [
                assign({
                  fromPostalCodeAddresses: true,
                  fromTheHome: false,
                  fromPickupReuse: false
                }),
                'sendQuotationWithAddressClicked'
              ]
            },
            {
              target: STATES.PICKUP_ADDRESS,
              actions: [
                assign({
                  fromPostalCodeAddresses: true,
                  fromTheHome: false,
                  fromPickupReuse: false
                }),
                'sendQuotationWithAddressClicked'
              ]
            }
          ]
        }
      },
      [STATES.PICKUP_ADDRESS]: {
        entry: ['persist'],
        on: {
          [EVENTS.CONTINUE]: [
            {
              /**
               * Sends the user back to the budget page when the new
               * pickup address is different from the previous one
               */
              target: STATES.BUDGET,
              cond: (ctx, ev) =>
                !!ctx.pickupAddress &&
                ctx.pickupAddress.placeId !== ev.payload.placeId,
              actions: [
                assign({
                  pickupAddress: (_, ev) => ev.payload,
                  serviceType: INITIAL_CONTEXT.serviceType,
                  backToSummary: INITIAL_CONTEXT.backToSummary
                }),
                'sendPickupAddressContinueClicked'
              ]
            },
            {
              target: STATES.DELIVERY_ADDRESS,
              cond: ctx => ctx.fromTheHome === true,
              actions: [
                assign({
                  pickupAddress: (_, ev) => ev.payload
                })
              ]
            },
            {
              target: STATES.BUDGET,
              cond: ctx => ctx.backToSummary === false,
              actions: [
                assign({
                  pickupAddress: (_, ev) => ev.payload,
                  originAddress: INITIAL_CONTEXT.originAddress,
                  destinationAddress: INITIAL_CONTEXT.destinationAddress
                }),
                'sendPickupAddressContinueClicked'
              ]
            },
            {
              target: STATES.SUMMARY_INDESPACHO,
              cond: ctx => !!ctx.backToSummary && isIndespacho(ctx.serviceType),
              actions: [
                assign({
                  pickupAddress: (_, ev) => ev.payload,
                  backToSummary: false
                }),
                'sendPickupAddressContinueClicked'
              ]
            },
            {
              target: STATES.SUMMARY,
              cond: ctx => ctx.backToSummary === true,
              actions: [
                assign({
                  pickupAddress: (_, ev) => ev.payload,
                  backToSummary: false
                }),
                'sendPickupAddressContinueClicked'
              ]
            }
          ],
          [EVENTS.BACK]: [
            {
              target: STATES.HOME,
              cond: ctx => ctx.fromTheHome === true
            },
            {
              target: STATES.BUDGET,
              cond: ctx => ctx.backToSummary === false
            },
            {
              target: STATES.SUMMARY,
              cond: ctx =>
                ctx.backToSummary === true &&
                !AmateurQuoting.isIndespacho({
                  serviceType: ctx.serviceType
                }),
              actions: assign({
                backToSummary: false
              })
            },
            {
              target: STATES.SUMMARY_INDESPACHO,
              cond: ctx => !!ctx.backToSummary && isIndespacho(ctx.serviceType),
              actions: assign({
                backToSummary: false
              })
            },
            {
              target: STATES.POSTAL_CODE_ADDRESSES,
              cond: ctx => ctx.fromPostalCodeAddresses === true,
              actions: [
                assign({
                  fromPostalCodeAddresses: false
                })
              ]
            }
          ],
          [EVENTS.CLICK_QUOTATION_WITH_POSTAL_CODE]: {
            target: STATES.POSTAL_CODE_ADDRESSES
          }
        }
      },
      [STATES.DELIVERY_ADDRESS]: {
        entry: ['persist'],
        on: {
          [EVENTS.CONTINUE]: [
            {
              cond: ctx => !!ctx.backToSummary && isIndespacho(ctx.serviceType),
              target: STATES.SUMMARY_INDESPACHO,
              actions: [
                assign({
                  deliveryAddress: (_, ev) => ev.payload,
                  backToSummary: false
                }),
                'sendDeliveryAddressContinueClicked'
              ]
            },
            {
              cond: ctx => ctx.backToSummary === true,
              target: STATES.SUMMARY,
              actions: [
                assign({
                  deliveryAddress: (_, ev) => ev.payload,
                  backToSummary: false
                }),
                'sendDeliveryAddressContinueClicked'
              ]
            },
            {
              cond: ctx => !!ctx.pickupOrderScheduleId,
              target: STATES.REQUIRE_PACKAGING,
              actions: [
                assign({ deliveryAddress: (_, ev) => ev.payload }),
                'sendDeliveryAddressContinueClicked'
              ]
            },
            {
              cond: ctx => !!ctx.pickupOrderScheduleId,
              target: STATES.PACKAGE_SIZE,
              actions: [
                assign({ deliveryAddress: (_, ev) => ev.payload }),
                'sendDeliveryAddressContinueClicked'
              ]
            },
            {
              cond: ctx =>
                ctx.backToSummary === false || ctx.fromTheHome === true,
              target: STATES.BUDGET,
              actions: [
                assign({
                  deliveryAddress: (_, ev) => ev.payload,
                  originAddress: INITIAL_CONTEXT.originAddress,
                  destinationAddress: INITIAL_CONTEXT.destinationAddress
                }),
                'sendDeliveryAddressContinueClicked'
              ]
            }
          ],
          [EVENTS.BACK]: [
            {
              target: STATES.PICKUP_ADDRESS,
              cond: ctx => ctx.fromTheHome === true
            },
            {
              target: STATES.BUDGET,
              cond: ctx => ctx.backToSummary === false
            },
            {
              target: STATES.SUMMARY_INDESPACHO,
              cond: ctx => !!ctx.backToSummary && isIndespacho(ctx.serviceType),
              actions: assign({
                backToSummary: false
              })
            },
            {
              target: STATES.SUMMARY,
              cond: ctx =>
                ctx.backToSummary === true &&
                !AmateurQuoting.isIndespacho({
                  serviceType: ctx.serviceType
                }),
              actions: assign({
                backToSummary: false
              })
            }
          ],
          [EVENTS.CLICK_QUOTATION_WITH_POSTAL_CODE]: {
            target: STATES.POSTAL_CODE_ADDRESSES
          }
        }
      },
      [STATES.ORIGIN_ADDRESS]: {
        exit: ['persist'],
        on: {
          [EVENTS.CONTINUE]: [
            {
              cond: ctx => !!ctx.backToSummary && isIndespacho(ctx.serviceType),
              target: STATES.SUMMARY_INDESPACHO,
              actions: assign({
                originAddress: (_, ev) => ev.payload
              })
            },
            {
              cond: ctx => ctx.backToSummary === true,
              target: STATES.SUMMARY,
              actions: assign({
                originAddress: (_, ev) => ev.payload
              })
            },
            {
              target: STATES.DESTINATION_ADDRESS,
              actions: assign({
                originAddress: (_, ev) => ev.payload
              })
            }
          ],
          [EVENTS.BACK]: [
            {
              target: STATES.DELIVERY_CUSTOMER,
              cond: ctx => isLoggiFacil(ctx.serviceType)
            },
            {
              target: STATES.ONBOARD_INDESPACHO,
              cond: ctx => AmateurQuoting.isIndespacho(ctx)
            },
            {
              target: STATES.LIST_AGENCIES,
              cond: ctx => AmateurQuoting.isDropoff(ctx.serviceType)
            }
          ]
        }
      },
      [STATES.DESTINATION_ADDRESS]: {
        exit: ['persist'],
        on: {
          [EVENTS.CONTINUE]: [
            {
              target: STATES.SUMMARY_INDESPACHO,
              cond: ctx => AmateurQuoting.isIndespacho(ctx),
              actions: assign({
                destinationAddress: (_, ev) => ev.payload
              })
            },
            {
              target: STATES.SUMMARY,
              actions: assign({
                destinationAddress: (_, ev) => ev.payload
              })
            }
          ],
          [EVENTS.BACK]: {
            target: STATES.ORIGIN_ADDRESS
          }
        }
      },
      [STATES.REQUIRE_PACKAGING]: {
        entry: ['persist'],
        on: {
          [EVENTS.SELECT_PACKAGING_SERVICE]: {
            actions: assign({ packagingService: (_, ev) => ev.payload })
          },
          [EVENTS.VIEW_ABOUT_PACKAGING]: STATES.ABOUT_PACKAGING,
          [EVENTS.CONTINUE]: [
            {
              cond: ctx => ctx.packagingService === SIMPLE_PACKAGING,
              target: STATES.NEW_PACKAGE_SIZE,
              // clear dimensions since it will be using loggi package
              actions: [
                assign({
                  packagingService: SIMPLE_PACKAGING,
                  packageDimensions: INITIAL_CONTEXT.packageDimensions
                }),
                'sendRequirePackagingContinueClicked'
              ]
            },
            {
              cond: ctx => ctx.packagingService === NO_PACKAGING,
              target: STATES.PACKAGE_DIMENSIONS,
              // clear package size option, since it will be using self provided package
              actions: [
                assign({
                  packagingService: NO_PACKAGING,
                  packageSize: INITIAL_CONTEXT.packageSize
                }),
                'sendRequirePackagingContinueClicked'
              ]
            }
          ],
          [EVENTS.BACK]: [
            {
              cond: ctx =>
                isGroupingShipmentInPickup(ctx) && isUsingInputAddress(ctx),
              target: STATES.POSTAL_CODE_ADDRESSES
            },
            {
              cond: ctx => !!ctx.pickupOrderScheduleId,
              target: STATES.DELIVERY_ADDRESS
            },
            {
              target: STATES.BUDGET
            }
          ]
        }
      },
      [STATES.NEW_PACKAGE_SIZE]: {
        entry: ['persist'],
        on: {
          [EVENTS.SELECT_PACKAGE_SIZE]: {
            actions: assign({ packageSize: (_, ev) => ev.payload })
          },
          [EVENTS.CONTINUE]: [
            {
              cond: ctx => !!ctx.pickupOrderScheduleId,
              target: STATES.INSURANCE
            },
            {
              target: STATES.PICKUP_SCHEDULE
            }
          ],
          [EVENTS.BACK]: STATES.REQUIRE_PACKAGING
        }
      },
      [STATES.PACKAGE_DIMENSIONS]: {
        entry: ['persist'],
        on: {
          [EVENTS.SET_PACKAGE_DIMENSIONS]: {
            actions: assign({ packageDimensions: (_, ev) => ev.payload })
          },
          [EVENTS.VIEW_ABOUT_PACKAGING]: STATES.ABOUT_PACKAGING,
          [EVENTS.CONTINUE]: [
            {
              cond: ctx =>
                AmateurQuoting.isIndespacho({ serviceType: ctx.serviceType }),
              target: STATES.SERVICES_INDESPACHO,
              actions: ['sendDimensionsContinueClicked']
            },
            {
              cond: ctx => AmateurQuoting.isDropoff(ctx.serviceType),
              target: STATES.PICKUP_CUSTOMER,
              actions: ['sendDimensionsContinueClicked']
            },
            {
              cond: ctx => !!ctx.pickupOrderScheduleId,
              target: STATES.INSURANCE,
              actions: ['sendDimensionsContinueClicked']
            },
            {
              target: STATES.PICKUP_SCHEDULE,
              actions: ['sendDimensionsContinueClicked']
            }
          ],
          [EVENTS.BACK]: [
            {
              cond: ctx =>
                AmateurQuoting.isIndespacho({ serviceType: ctx.serviceType }),
              target: STATES.BUDGET
            },
            {
              cond: ctx => AmateurQuoting.isDropoff(ctx.serviceType),
              target: STATES.BUDGET
            },
            {
              target: STATES.REQUIRE_PACKAGING
            }
          ]
        }
      },
      [STATES.ABOUT_PACKAGING]: {
        on: {
          [EVENTS.BACK]: STATES.REQUIRE_PACKAGING
        }
      },
      [STATES.PICKUP_SCHEDULE]: {
        entry: ['persist'],
        on: {
          [EVENTS.NO_PICKUP_SCHEDULE]: {
            target: STATES.PICKUP_ADDRESS
          },
          [EVENTS.CONTINUE]: [
            {
              target: STATES.INSURANCE,
              actions: [
                assign({ pickupSchedule: (_, ev) => ev.payload }),
                'sendPickupScheduleSlotSelected'
              ]
            }
          ],
          [EVENTS.BACK]: [
            {
              target: STATES.BUDGET,
              cond: ctx =>
                ctx.backToSummary === false &&
                ctx.packagingService === NOT_INFORMED
            },
            {
              target: STATES.NEW_PACKAGE_SIZE,
              cond: ctx =>
                ctx.backToSummary === false &&
                ctx.packagingService === SIMPLE_PACKAGING
            },
            {
              target: STATES.PACKAGE_DIMENSIONS,
              cond: ctx =>
                ctx.backToSummary === false &&
                ctx.packagingService === NO_PACKAGING
            },
            {
              cond: ctx =>
                ctx.backToSummary === true &&
                AmateurQuoting.isIndespacho({ serviceType: ctx.serviceType }),
              target: STATES.SUMMARY_INDESPACHO,
              actions: assign({
                backToSummary: false
              })
            },
            {
              cond: ctx =>
                ctx.backToSummary === true &&
                !AmateurQuoting.isIndespacho({ serviceType: ctx.serviceType }),
              target: STATES.SUMMARY,
              actions: assign({
                backToSummary: false
              })
            }
          ]
        }
      },
      [STATES.ONBOARD_INDESPACHO]: {
        entry: ['persist'],
        on: {
          [EVENTS.CONTINUE]: [
            {
              target: STATES.ORIGIN_ADDRESS,
              cond: ctx => isUsingInputAddress(ctx)
            },
            {
              target: STATES.SUMMARY_INDESPACHO
            }
          ],
          [EVENTS.BACK]: {
            target: STATES.INSURANCE
          }
        }
      },
      [STATES.INSURANCE]: {
        entry: ['persist'],
        on: {
          [EVENTS.SET_DECLARED_VALUE]: {
            actions: assign({
              insurance: (_, ev) => ev.payload
            }),
            target: STATES.INSURANCE
          },
          [EVENTS.CONTINUE]: [
            {
              cond: ctx => !!ctx.pickupOrderScheduleId,
              target: STATES.DELIVERY_CUSTOMER,
              actions: [
                assign({
                  insurance: (_, ev) => ev.payload
                }),
                'sendContentDeclarationContinueClicked'
              ]
            },
            {
              cond: ctx =>
                AmateurQuoting.isIndespacho({ serviceType: ctx.serviceType }),
              target: STATES.ONBOARD_INDESPACHO,
              actions: [
                assign({
                  insurance: (_, ev) => ev.payload
                }),
                'sendContentDeclarationContinueClicked'
              ]
            },
            {
              cond: ctx => AmateurQuoting.isDropoff(ctx.serviceType),
              target: STATES.LIST_AGENCIES,
              actions: [
                assign({
                  insurance: (_, ev) => ev.payload
                }),
                'sendContentDeclarationContinueClicked'
              ]
            },
            {
              target: STATES.PICKUP_CUSTOMER,
              actions: [
                assign({
                  insurance: (_, ev) => ev.payload
                }),
                'sendContentDeclarationContinueClicked'
              ]
            }
          ],
          [EVENTS.BACK]: [
            /** transitions for grouped shipment */
            {
              cond: ctx =>
                !!ctx.pickupOrderScheduleId &&
                ctx.packagingService === NO_PACKAGING,
              target: STATES.PACKAGE_DIMENSIONS
            },
            {
              cond: ctx =>
                !!ctx.pickupOrderScheduleId &&
                ctx.packagingService === SIMPLE_PACKAGING,
              target: STATES.NEW_PACKAGE_SIZE
            },
            {
              cond: ctx => !!ctx.pickupOrderScheduleId,
              target: STATES.PACKAGE_SIZE
            },
            /** end transitions for grouped shipment */
            {
              cond: ctx =>
                AmateurQuoting.isIndespacho({ serviceType: ctx.serviceType }),
              target: STATES.DELIVERY_CUSTOMER
            },
            {
              cond: ctx => AmateurQuoting.isDropoff(ctx.serviceType),
              target: STATES.DELIVERY_CUSTOMER
            },
            { target: STATES.PICKUP_SCHEDULE }
          ]
        }
      },
      [STATES.LIST_AGENCIES]: {
        on: {
          [EVENTS.CONTINUE]: STATES.DROPOFF_ONBOARDING,
          [EVENTS.BACK]: STATES.INSURANCE
        }
      },
      [STATES.DROPOFF_ONBOARDING]: {
        on: {
          [EVENTS.CONTINUE]: [
            {
              target: STATES.ORIGIN_ADDRESS,
              cond: ctx => isUsingInputAddress(ctx)
            },
            { target: STATES.SUMMARY }
          ],
          [EVENTS.BACK]: STATES.LIST_AGENCIES
        }
      },
      [STATES.PACKAGE_SIZE]: {
        entry: ['persist'],
        on: {
          [EVENTS.CONTINUE]: {
            target: STATES.INSURANCE,
            actions: [
              assign({
                packageSize: (_, ev) => ev.payload
              })
            ]
          },
          [EVENTS.BACK]: {
            target: STATES.DELIVERY_ADDRESS
          }
        }
      },
      [STATES.PICKUP_CUSTOMER]: {
        entry: ['persist'],
        on: {
          [EVENTS.CONTINUE]: {
            target: STATES.DELIVERY_CUSTOMER,
            actions: [
              assign({
                pickupCustomer: (_, ev) => ev.payload
              })
            ]
          },
          [EVENTS.BACK]: [
            {
              cond: ctx =>
                AmateurQuoting.isIndespacho({ serviceType: ctx.serviceType }),
              target: STATES.SERVICES_INDESPACHO
            },
            {
              cond: ctx => AmateurQuoting.isDropoff(ctx.serviceType),
              target: STATES.PACKAGE_DIMENSIONS
            },
            {
              cond: ctx =>
                ctx.serviceType === 'AMATEUR_SERVICE_TYPE_LOCAL_EXPRESS',
              target: STATES.BUDGET
            },
            { target: STATES.INSURANCE }
          ]
        }
      },
      [STATES.DELIVERY_CUSTOMER]: {
        entry: ['persist'],
        on: {
          [EVENTS.SET_DELIVERY_CUSTOMER]: {
            target: STATES.DELIVERY_CUSTOMER,
            actions: assign({
              deliveryCustomer: (_, ev) => ev.payload
            })
          },
          [EVENTS.CONTINUE]: [
            {
              cond: ctx =>
                ctx.serviceType === 'AMATEUR_SERVICE_TYPE_LOCAL_EXPRESS',
              target: STATES.IDLE,
              actions: [
                assign({
                  deliveryCustomer: (_, ev) => ev.payload
                }),
                'sendDeliveryCustomerContinueClicked'
              ]
            },
            {
              target: STATES.DESTINATION_ADDRESS,
              cond: ctx =>
                isLoggiFacil(ctx.serviceType) &&
                isUsingInputAddress(ctx) &&
                isGroupingShipmentInPickup(ctx)
            },
            {
              target: STATES.ORIGIN_ADDRESS,
              cond: ctx =>
                isLoggiFacil(ctx.serviceType) && isUsingInputAddress(ctx)
            },
            {
              cond: ctx =>
                ctx.serviceType === 'AMATEUR_SERVICE_TYPE_NATIONAL_ECONOMIC',
              target: STATES.SUMMARY,
              actions: ['sendDeliveryCustomerContinueClicked']
            },
            {
              cond: ctx => isIndespacho(ctx.serviceType),
              target: STATES.INSURANCE,
              actions: ['sendDeliveryCustomerContinueClicked']
            },
            {
              cond: ctx => AmateurQuoting.isDropoff(ctx.serviceType),
              target: STATES.INSURANCE,
              actions: ['sendDeliveryCustomerContinueClicked']
            }
          ],
          [EVENTS.BACK]: [
            {
              cond: ctx => !!ctx.pickupOrderScheduleId,
              target: STATES.INSURANCE
            },
            { target: STATES.PICKUP_CUSTOMER }
          ]
        }
      },
      [STATES.SUMMARY]: {
        entry: ['persist', assign({ backToSummary: false })],
        on: {
          [EVENTS.EDIT_PICKUP_ADDRESS]: [
            {
              cond: ctx => !!ctx.pickupOrderScheduleId,
              target: STATES.SUMMARY
            },
            redirectToAddressState(
              STATES.ORIGIN_ADDRESS,
              assign({
                backToSummary: true
              }),
              ctx => ctx.originAddress !== null
            ),
            redirectToAddressState(
              STATES.PICKUP_ADDRESS,
              assign({
                backToSummary: true
              })
            )
          ],
          [EVENTS.EDIT_DELIVERY_ADDRESS]: [
            redirectToAddressState(
              STATES.DESTINATION_ADDRESS,
              assign({
                backToSummary: true
              }),
              ctx => ctx.destinationAddress !== null
            ),
            redirectToAddressState(
              STATES.DELIVERY_ADDRESS,
              assign({
                backToSummary: true
              })
            )
          ],
          [EVENTS.INVALID_PICKUP_SCHEDULE]: {
            target: STATES.PICKUP_SCHEDULE,
            actions: assign({
              pickupSchedule: null,
              backToSummary: true
            })
          },
          [EVENTS.VIEW_COUPONS]: {
            target: STATES.COUPONS
          },
          [EVENTS.SET_COUPON]: {
            target: STATES.SUMMARY,
            actions: assign({
              coupon: (_, ev) => ev.payload
            })
          },
          [EVENTS.CLEAR]: {
            target: STATES.HOME,
            actions: ['resetPersistedContext', 'resetContext']
          },
          [EVENTS.ORDER_CREATED]: [
            {
              target: STATES.SHIPMENT_CREATED_V2,
              actions: [
                assign({
                  shipmentOrder: (_, ev) => ev.payload
                }),
                'sendShipmentCreationCompleted'
              ]
            }
          ],
          [EVENTS.BACK]: [
            {
              cond: ctx => AmateurQuoting.isDropoff(ctx.serviceType),
              target: STATES.LIST_AGENCIES
            },
            {
              cond: ctx => isUsingInputAddress(ctx),
              target: STATES.DESTINATION_ADDRESS
            },
            { target: STATES.DELIVERY_CUSTOMER }
          ],
          [EVENTS.CONTINUE]: [
            {
              target: STATES.PAYMENT
            }
          ]
        }
      },
      [STATES.SUMMARY_INDESPACHO]: {
        entry: ['persist'],
        on: {
          [EVENTS.EDIT_PICKUP_ADDRESS]: [
            redirectToAddressState(
              STATES.ORIGIN_ADDRESS,
              assign({
                backToSummary: true
              }),
              ctx => ctx.originAddress !== null
            ),
            redirectToAddressState(
              STATES.PICKUP_ADDRESS,
              assign({
                backToSummary: true
              })
            )
          ],
          [EVENTS.EDIT_DELIVERY_ADDRESS]: [
            redirectToAddressState(
              STATES.DESTINATION_ADDRESS,
              assign({
                backToSummary: true
              }),
              ctx => ctx.destinationAddress !== null
            ),
            redirectToAddressState(
              STATES.DELIVERY_ADDRESS,
              assign({
                backToSummary: true
              })
            )
          ],
          [EVENTS.VIEW_COUPONS]: {
            target: STATES.COUPONS
          },
          [EVENTS.SET_COUPON]: {
            target: STATES.SUMMARY_INDESPACHO,
            actions: assign({
              coupon: (_, ev) => ev.payload
            })
          },
          [EVENTS.CLEAR]: {
            target: STATES.BUDGET,
            actions: ['resetPersistedContext', 'resetContext']
          },
          [EVENTS.ORDER_CREATED]: {
            target: STATES.SHIPMENT_CREATED_INDESPACHO,
            actions: [
              assign({
                shipmentOrder: (_, ev) => ev.payload
              }),
              'sendShipmentCreationCompleted'
            ]
          },
          [EVENTS.BACK]: [
            {
              target: STATES.DESTINATION_ADDRESS,
              cond: ctx => isUsingInputAddress(ctx)
            },
            {
              target: STATES.ONBOARD_INDESPACHO
            }
          ],
          [EVENTS.CONTINUE]: [
            {
              target: STATES.PAYMENT
            }
          ]
        }
      },
      [STATES.SERVICES_INDESPACHO]: {
        on: {
          [EVENTS.BACK]: {
            target: STATES.PACKAGE_DIMENSIONS
          },
          [EVENTS.CONTINUE]: {
            actions: assign({
              serviceType: (_, ev) => ev.payload
            }),
            target: STATES.PICKUP_CUSTOMER
          }
        }
      },
      [STATES.COUPONS]: {
        entry: ['persist'],
        on: {
          [EVENTS.SET_COUPON]: {
            target: STATES.COUPONS,
            actions: assign({
              coupon: (_, ev) => ev.payload
            })
          },
          [EVENTS.CONTINUE]: [
            {
              cond: ctx =>
                AmateurQuoting.isIndespacho({
                  serviceType: ctx.serviceType
                }),
              target: STATES.SUMMARY_INDESPACHO
            },
            {
              target: STATES.SUMMARY
            }
          ],
          [EVENTS.BACK]: [
            {
              cond: ctx =>
                AmateurQuoting.isIndespacho({
                  serviceType: ctx.serviceType
                }),
              target: STATES.SUMMARY_INDESPACHO
            },
            {
              target: STATES.SUMMARY
            }
          ]
        }
      },
      [STATES.PAYMENT_COUPONS]: {
        entry: ['persist'],
        on: {
          [EVENTS.SET_COUPON]: {
            target: STATES.PAYMENT_COUPONS,
            actions: [
              assign({
                coupon: (_, ev) => ev.payload
              })
            ]
          },
          [EVENTS.CONTINUE]: [
            {
              target: STATES.PAYMENT,
              actions: 'sendShipmentCouponInputed'
            }
          ],
          [EVENTS.BACK]: [
            {
              target: STATES.PAYMENT
            }
          ]
        }
      },
      [STATES.PAYMENT]: {
        on: {
          [EVENTS.CLEAR]: {
            target: STATES.HOME,
            actions: ['resetPersistedContext', 'resetContext']
          },
          [EVENTS.SET_PAYMENT_METHOD]: {
            targe: STATES.PAYMENT,
            actions: [
              assign({
                paymentMethod: (_, ev) => ev.payload
              })
            ]
          },
          [EVENTS.VIEW_COUPONS]: {
            target: STATES.PAYMENT_COUPONS
          },
          [EVENTS.CLICK_ADD_WALLET_BALANCE]: {
            target: STATES.ADD_WALLET_BALANCE_OPTIONS,
            actions: [
              assign({
                shipmentAmount: (_, ev) => ev.payload,
                addBalanceFrom: STATES.PAYMENT
              })
            ]
          },
          [EVENTS.SELECT_PIX_PAYMENT]: [
            {
              target: STATES.PIX_PAYMENT,
              actions: [
                assign({
                  paymentMethod: (_, ev) => ev.payload,
                  rechargeFrom: null
                })
              ]
            }
          ],
          [EVENTS.ORDER_CREATED]: [
            {
              cond: ctx =>
                AmateurQuoting.isIndespacho({
                  serviceType: ctx.serviceType
                }),
              target: STATES.SHIPMENT_CREATED_INDESPACHO,
              actions: [
                assign({
                  shipmentOrder: (_, ev) => ev.payload
                }),
                'sendShipmentCreationCompleted'
              ]
            },
            {
              cond: ctx =>
                !AmateurQuoting.isIndespacho({
                  serviceType: ctx.serviceType
                }),
              target: STATES.SHIPMENT_CREATED_V2,
              actions: [
                assign({
                  shipmentOrder: (_, ev) => ev.payload
                }),
                'sendShipmentCreationCompleted'
              ]
            }
          ],
          [EVENTS.BACK]: [
            {
              cond: ctx =>
                AmateurQuoting.isIndespacho({
                  serviceType: ctx.serviceType
                }),
              target: STATES.SUMMARY_INDESPACHO
            },
            {
              target: STATES.SUMMARY
            }
          ],
          [EVENTS.VIEW_WALLET_BALANCE_ERROR]: [
            {
              target: STATES.ADD_WALLET_BALANCE_ERROR,
              actions: [
                assign({
                  paymentMethod: (_, ev) => ev.payload?.paymentMethod,
                  addBalanceError: (_, ev) => ev.payload?.addBalanceError,
                  rechargeFrom: null
                })
              ]
            }
          ],
          [EVENTS.CLICK_PICKUP_ADDRESS]: [
            {
              target: STATES.PICKUP_ADDRESS
            }
          ]
        }
      },
      [STATES.PIX_PAYMENT]: {
        entry: ['persist'],
        on: {
          [EVENTS.VIEW_WALLET_BALANCE_ERROR]: [
            {
              target: STATES.ADD_WALLET_BALANCE_ERROR,
              actions: [
                assign({
                  addBalanceError: (_, ev) => ev.payload?.addBalanceError,
                  paymentMethod: (_, ev) => ev.payload?.paymentMethod
                })
              ]
            }
          ],
          [EVENTS.CLEAR]: {
            target: STATES.HOME,
            actions: ['resetPersistedContext', 'resetContext']
          },
          [EVENTS.CANCEL_PIX_PAYMENT]: [
            {
              cond: ctx => ctx.rechargeFrom === STATES.INPUT_RECHARGE_VALUE,
              target: STATES.INPUT_RECHARGE_VALUE,
              actions: [
                assign({
                  rechargeValue: null,
                  rechargeFrom: null
                })
              ]
            },
            {
              cond: ctx =>
                ctx.rechargeFrom === STATES.PRE_DEFINED_RECHARGE_OPTIONS,
              target: STATES.PRE_DEFINED_RECHARGE_OPTIONS,
              actions: [
                assign({
                  rechargeValue: null,
                  rechargeFrom: null
                })
              ]
            },
            {
              target: STATES.PAYMENT,
              actions: [
                assign({
                  rechargeValue: null,
                  rechargeFrom: null
                })
              ]
            }
          ],
          [EVENTS.ORDER_CREATED]: [
            {
              target: STATES.SHIPMENT_CREATED_V2,
              actions: [
                assign({
                  shipmentOrder: (_, ev) => ev.payload
                }),
                'sendShipmentCreationCompleted'
              ]
            }
          ],
          [EVENTS.BALANCE_SUCCESSFULLY_ADDED]: [
            {
              cond: ctx =>
                !ctx.featureSwitches
                  .enable_payment_session_data_from_api_response,
              target: STATES.WALLET_BALANCE_SUCCESSFULLY_ADDED,
              actions: [assign(setBalanceSuccessullyAdded)]
            },
            {
              cond: ctx =>
                ctx.featureSwitches
                  .enable_payment_session_data_from_api_response,
              target: STATES.WALLET_BALANCE_SUCCESSFULLY_ADDED,
              actions: [assign(setBalanceSuccessullyAddedFromApi)]
            }
          ],
          [EVENTS.CLICK_PICKUP_ADDRESS]: [
            {
              target: STATES.PICKUP_ADDRESS
            }
          ]
        }
      },
      [STATES.ADD_WALLET_BALANCE_OPTIONS]: {
        entry: ['persist'],
        on: {
          [EVENTS.BACK]: [
            {
              cond: ctx => ctx.addBalanceFrom === STATES.HOME,
              target: STATES.HOME
            },
            {
              cond: ctx => ctx.addBalanceFrom === STATES.WALLET,
              target: STATES.WALLET
            },
            {
              target: STATES.PAYMENT
            }
          ],
          [EVENTS.CONTINUE]: [
            {
              target: STATES.PRE_DEFINED_RECHARGE_OPTIONS,
              actions: [
                assign({
                  paymentMethod: (_, ev) => ev.payload
                })
              ]
            }
          ],
          [EVENTS.SET_CURRENT_BALANCE]: [
            {
              cond: ctx =>
                !ctx.featureSwitches
                  .enable_payment_session_data_from_api_response,
              actions: [
                assign({
                  currentBalance: (_, ev) => ev.payload
                })
              ]
            }
          ]
        }
      },
      [STATES.WALLET_BALANCE_SUCCESSFULLY_ADDED]: {
        entry: ['sendWalletCreditAdded'],
        on: {
          [EVENTS.CONTINUE]: [
            {
              cond: ctx => ctx.addBalanceFrom === STATES.HOME,
              target: STATES.HOME,
              actions: [
                assign({
                  rechargeId: null,
                  rechargeValue: null
                })
              ]
            },
            {
              cond: ctx => ctx.addBalanceFrom === STATES.WALLET,
              target: STATES.WALLET,
              actions: [
                assign({
                  rechargeId: null,
                  rechargeValue: null
                })
              ]
            },
            {
              target: STATES.PAYMENT,
              actions: [
                assign({
                  rechargeId: null,
                  rechargeValue: null
                })
              ]
            }
          ]
        }
      },
      [STATES.ADD_WALLET_BALANCE_ERROR]: {
        on: {
          [EVENTS.BACK]: [
            {
              cond: ctx => ctx.rechargeFrom === STATES.INPUT_RECHARGE_VALUE,
              target: STATES.INPUT_RECHARGE_VALUE,
              actions: [
                assign({
                  rechargeId: null,
                  rechargeValue: null,
                  addBalanceError: null
                })
              ]
            },
            {
              cond: ctx =>
                ctx.rechargeFrom === STATES.PRE_DEFINED_RECHARGE_OPTIONS,
              target: STATES.PRE_DEFINED_RECHARGE_OPTIONS
            },
            {
              target: STATES.PAYMENT
            }
          ]
        }
      },
      [STATES.PRE_DEFINED_RECHARGE_OPTIONS]: {
        on: {
          [EVENTS.BACK]: [
            {
              target: STATES.ADD_WALLET_BALANCE_OPTIONS
            }
          ],
          [EVENTS.CONTINUE]: [
            {
              target: STATES.PIX_PAYMENT,
              actions: [
                assign({
                  rechargeValue: (_, ev) => ev.payload,
                  rechargeFrom: STATES.PRE_DEFINED_RECHARGE_OPTIONS
                })
              ]
            }
          ],
          [EVENTS.CLICK_OTHER_VALUE]: {
            target: STATES.INPUT_RECHARGE_VALUE
          },
          [EVENTS.BALANCE_SUCCESSFULLY_ADDED]: [
            {
              cond: ctx =>
                !ctx.featureSwitches
                  .enable_payment_session_data_from_api_response,
              target: STATES.WALLET_BALANCE_SUCCESSFULLY_ADDED,
              actions: [assign(setBalanceSuccessullyAdded)]
            },
            {
              cond: ctx =>
                ctx.featureSwitches
                  .enable_payment_session_data_from_api_response,
              target: STATES.WALLET_BALANCE_SUCCESSFULLY_ADDED,
              actions: [assign(setBalanceSuccessullyAddedFromApi)]
            }
          ],
          [EVENTS.VIEW_WALLET_BALANCE_ERROR]: [
            {
              target: STATES.ADD_WALLET_BALANCE_ERROR,
              actions: [
                assign({
                  rechargeFrom: STATES.PRE_DEFINED_RECHARGE_OPTIONS,
                  paymentMethod: (_, ev) => ev.payload?.paymentMethod,
                  addBalanceError: (_, ev) => ev.payload?.addBalanceError
                })
              ]
            }
          ]
        }
      },
      [STATES.INPUT_RECHARGE_VALUE]: {
        on: {
          [EVENTS.BACK]: [
            {
              target: STATES.PRE_DEFINED_RECHARGE_OPTIONS
            }
          ],
          [EVENTS.CONTINUE]: [
            {
              target: STATES.PIX_PAYMENT,
              actions: [
                assign({
                  rechargeValue: (_, ev) => ev.payload,
                  rechargeFrom: STATES.INPUT_RECHARGE_VALUE
                })
              ]
            }
          ],
          [EVENTS.BALANCE_SUCCESSFULLY_ADDED]: [
            {
              cond: ctx =>
                !ctx.featureSwitches
                  .enable_payment_session_data_from_api_response,
              target: STATES.WALLET_BALANCE_SUCCESSFULLY_ADDED,
              actions: [assign(setBalanceSuccessullyAdded)]
            },
            {
              cond: ctx =>
                ctx.featureSwitches
                  .enable_payment_session_data_from_api_response,
              target: STATES.WALLET_BALANCE_SUCCESSFULLY_ADDED,
              actions: [assign(setBalanceSuccessullyAddedFromApi)]
            }
          ],
          [EVENTS.VIEW_WALLET_BALANCE_ERROR]: [
            {
              target: STATES.ADD_WALLET_BALANCE_ERROR,
              actions: [
                assign({
                  rechargeFrom: STATES.INPUT_RECHARGE_VALUE,
                  paymentMethod: (_, ev) => ev.payload?.paymentMethod,
                  addBalanceError: (_, ev) => ev.payload?.addBalanceError
                })
              ]
            }
          ]
        }
      },
      [STATES.SHIPMENT_CREATED]: {
        on: {
          [EVENTS.CONTINUE]: STATES.IDLE
        }
      },
      [STATES.SHIPMENT_CREATED_V2]: {
        on: {
          [EVENTS.CONTINUE]: STATES.IDLE
        }
      },
      [STATES.SHIPMENT_CREATED_INDESPACHO]: {
        on: {
          [EVENTS.CONTINUE]: STATES.IDLE
        }
      }
    }
  });
}

const EVENTS = {
  SEND_USER: 'send_user',
  ACCOUNT_CREATION_COMPLETED: 'account_creation_completed',
  ACCOUNT_CREATION_COMPLETED_PJ: 'account_creation_completed_pj',
  ACCOUNT_CREATION_COMPLETED_PLUS_30: 'account_creation_completed_plus_30',
  ACCOUNT_CREATION_COMPLETED_ROAS: 'account_creation_completed_roas',
  ACCOUNT_CREATION_COMPLETED_ROAS_PJ: 'account_creation_completed_roas_pj',
  ACCOUNT_CREATION_STARTED: 'account_creation_started',
  ACCOUNT_DELETED: 'account_deleted',
  ACCOUNT_SHIPMENT_QUALIFICATION_INFORMED:
    'account_shipment_qualification_informed',
  ACCOUNT_VOLUME_QUALIFICATION_INFORMED:
    'account_volume_qualification_informed',
  ACCESS_STARTED: 'access_started',
  ACCOUNT_CREATION_SUBMITTED: 'account_creation_submitted',
  ACCOUNT_TYPE_INFORMED: 'account_type_informed',
  ACCOUNT_PHONE_INFORMED: 'account_phone_informed',
  ACCOUNT_DOCUMENT_INFORMED: 'account_document_informed',
  ACCOUNT_ZIPCODE_INFORMED: 'account_zipcode_informed',
  ACCOUNT_SEGMENT_INFORMED: 'account_segment_informed',
  ACCOUNT_INTEGRATORS_INFORMED: 'account_integrators_informed',
  ACCOUNT_CREATION_FAILED: 'account_creation_failed',
  ACCOUNT_AVERAGE_TICKET_INFORMED: 'account_average_ticket_informed',
  SHIPMENT_ADDRESS_BY_CEP_SELECTED: 'shipment_address_by_cep_selected',
  SHIPMENT_ADDRESS_BY_CEP_QUOTATION_SENT:
    'shipment_address_by_cep_quotation_sent',
  SHIPMENT_ADDRESS_BY_CEP_QUOTATION_FAILED:
    'shipment_address_by_cep_quotation_failed',
  SHIPMENT_ADDRESS_BY_CEP_ORIGIN_INFOS_INPUTED:
    'shipment_address_by_cep_origin_infos_inputed',
  SHIPMENT_ADDRESS_BY_CEP_ORIGIN_INFOS_FAILED:
    'shipment_address_by_cep_origin_infos_failed',
  SHIPMENT_ADDRESS_BY_CEP_DESTINATION_INFOS_INPUTED:
    'shipment_address_by_cep_destination_infos_inputed',
  SHIPMENT_ADDRESS_BY_CEP_DESTINATION_INFOS_FAILED:
    'shipment_address_by_cep_destination_infos_failed',
  SHIPMENT_ADDRESS_BY_CEP_CORP_ALERT_CLICKED:
    'shipment_address_by_cep_corp_alert_clicked',
  SHIPMENT_ADDRESS_BY_GOOGLE_SELECTED: 'shipment_address_by_google_selected',
  SHIPMENT_CREATION_COMPLETED: 'shipment_creation_completed',
  SHIPMENT_CREATION_STARTED: 'shipment_creation_started',
  SHIPMENT_DIMENSIONS_INPUTED: 'shipment_dimensions_inputed',
  SHIPMENT_SERVICE_SELECTED: 'shipment_service_selected',
  SHIPMENT_CREATION_SENT: 'shipment_creation_sent',
  SHIPMENT_SENT_PURCHASE: 'shipment_sent_purchase',
  SHIPMENT_QUOTATION_PRESENTED: 'shipment_quotation_presented',
  SHIPMENT_CANCELLED: 'shipment_cancelled',
  SHIPMENT_CREATION_FAILED: 'shipment_creation_failed',
  SHIPMENT_DESTINATION_INPUTED: 'shipment_destination_inputed',
  SHIPMENT_DESTINATION_INPUT_STARTED: 'shipment_destination_input_started',
  SHIPMENT_ORIGIN_INPUTED: 'shipment_origin_inputed',
  SHIPMENT_GOODSVALUE_INPUTED: 'shipment_goodsvalue_inputed',
  SHIPMENT_COUPON_INPUTED: 'shipment_coupon_inputed',
  ACCESS_COMPLETED: 'access_completed',
  HOME_CORP_BUTTON_CLICKED: 'home_corp_button_clicked',
  HOME_SERVICE_CLICKED: 'home_service_clicked',
  HOME_SERVICES_CHECKED: 'home_services_checked',
  HOME_VISITED: 'home_visited',
  PRESIGN_VISITED: 'presign_visited',
  PAGE_CHECKOUT_VIEWED: 'page_checkout_viewed',
  PAGE_DATA_CONFIRMATION_VIEWED: 'page_data_confirmation_viewed',
  PAGE_SENDER_VIEWED: 'page_sender_viewed',
  PAGE_RECIPIENT_VIEWED: 'page_recipient_viewed',
  MENU_OPTION_SELECTED: 'menu_option_selected',
  USER_CREATION_INTENDED: 'user_creation_intended',
  PAGE_DIMENSIONS_VIEWED: 'page_dimensions_viewed',
  PAGE_GOODSVALUE_VIEWED: 'page_goodsvalue_viewed',
  SHIPMENT_PACKAGING_INPUTED: 'shipment_packing_inputed',
  USER_INTENTION_INFORMED: 'user_intention_informed',
  MENU_OPENED: 'menu_opened',
  SHIPMENT_RECIPIENT_INPUTED: 'shipment_recipient_inputed',
  ACCOUNT_LOGIN_VIEWED: 'account_login_viewed',
  PAGE_PICKUP_SCHEDULE_VIEWED: 'page_pickup_schedule_viewed',
  COUPON_BANNER_CHECKED: 'coupon_banner_checked',
  PICKUP_SCHEDULE_SLOT_SELECTED: 'pickup_schedule_slot_selected',
  ACCOUNT_LOGGED_OUT: 'account_logged_out',
  SHIPMENT_DESTINATION_BY_GOOGLE_INPUT_STARTED:
    'shipment_destination_by_google_input_started',
  SHIPMENT_DESTINATION_BY_GOOGLE_ADDRESS_SELECTED:
    'shipment_destination_by_google_address_selected',
  SHIPMENT_DESTINATION_BY_GOOGLE_ALERT_PRESENTED:
    'shipment_destination_by_google_alert_presented',
  SHIPMENT_DESTINATION_BY_GOOGLE_ALERT_ANSWERED:
    'shipment_destination_by_google_alert_answered',
  SHIPMENT_ORIGIN_BY_GOOGLE_INPUT_STARTED:
    'shipment_origin_by_google_input_started',
  SHIPMENT_ORIGIN_BY_GOOGLE_ADDRESS_SELECTED:
    'shipment_origin_by_google_address_selected',
  SHIPMENT_ORIGIN_BY_GOOGLE_ALERT_PRESENTED:
    'shipment_origin_by_google_alert_presented',
  SHIPMENT_ORIGIN_BY_GOOGLE_ALERT_ANSWERED:
    'shipment_origin_by_google_alert_answered',
  WALLET_CREDIT_ADDED: 'wallet_credit_added'
};

export const REVENUE_EVENTS = {
  SHIPMENT_CREATION_COMPLETED: EVENTS.SHIPMENT_CREATION_COMPLETED
};

export const INSIDER_EVENTS = [
  EVENTS.ACCESS_COMPLETED,
  EVENTS.ACCESS_STARTED,
  EVENTS.ACCOUNT_CREATION_COMPLETED,
  EVENTS.ACCOUNT_CREATION_COMPLETED_PJ,
  EVENTS.ACCOUNT_CREATION_COMPLETED_ROAS,
  EVENTS.ACCOUNT_CREATION_COMPLETED_ROAS_PJ,
  EVENTS.ACCOUNT_CREATION_COMPLETED_PLUS_30,
  EVENTS.ACCOUNT_CREATION_STARTED,
  EVENTS.ACCOUNT_CREATION_SUBMITTED,
  EVENTS.ACCOUNT_DELETED,
  EVENTS.ACCOUNT_LOGGED_OUT,
  EVENTS.ACCOUNT_LOGIN_VIEWED,
  EVENTS.ACCOUNT_SHIPMENT_QUALIFICATION_INFORMED,
  EVENTS.ACCOUNT_VOLUME_QUALIFICATION_INFORMED,
  EVENTS.ACCOUNT_TYPE_INFORMED,
  EVENTS.ACCOUNT_PHONE_INFORMED,
  EVENTS.ACCOUNT_DOCUMENT_INFORMED,
  EVENTS.ACCOUNT_ZIPCODE_INFORMED,
  EVENTS.ACCOUNT_SEGMENT_INFORMED,
  EVENTS.ACCOUNT_INTEGRATORS_INFORMED,
  EVENTS.ACCOUNT_CREATION_FAILED,
  EVENTS.ACCOUNT_AVERAGE_TICKET_INFORMED,
  EVENTS.COUPON_BANNER_CHECKED,
  EVENTS.HOME_CORP_BUTTON_CLICKED,
  EVENTS.HOME_SERVICES_CHECKED,
  EVENTS.HOME_SERVICE_CLICKED,
  EVENTS.HOME_VISITED,
  EVENTS.MENU_OPENED,
  EVENTS.MENU_OPTION_SELECTED,
  EVENTS.PAGE_CHECKOUT_VIEWED,
  EVENTS.PAGE_DATA_CONFIRMATION_VIEWED,
  EVENTS.PAGE_DIMENSIONS_VIEWED,
  EVENTS.PAGE_GOODSVALUE_VIEWED,
  EVENTS.PAGE_PICKUP_SCHEDULE_VIEWED,
  EVENTS.PAGE_RECIPIENT_VIEWED,
  EVENTS.PAGE_SENDER_VIEWED,
  EVENTS.PICKUP_SCHEDULE_SLOT_SELECTED,
  EVENTS.PRESIGN_VISITED,
  EVENTS.SEND_USER,
  EVENTS.SHIPMENT_ADDRESS_BY_CEP_SELECTED,
  EVENTS.SHIPMENT_ADDRESS_BY_CEP_QUOTATION_SENT,
  EVENTS.SHIPMENT_ADDRESS_BY_CEP_QUOTATION_FAILED,
  EVENTS.SHIPMENT_ADDRESS_BY_CEP_ORIGIN_INFOS_INPUTED,
  EVENTS.SHIPMENT_ADDRESS_BY_CEP_ORIGIN_INFOS_FAILED,
  EVENTS.SHIPMENT_ADDRESS_BY_CEP_DESTINATION_INFOS_INPUTED,
  EVENTS.SHIPMENT_ADDRESS_BY_CEP_DESTINATION_INFOS_FAILED,
  EVENTS.SHIPMENT_ADDRESS_BY_CEP_CORP_ALERT_CLICKED,
  EVENTS.SHIPMENT_ADDRESS_BY_GOOGLE_SELECTED,
  EVENTS.SHIPMENT_CANCELLED,
  EVENTS.SHIPMENT_CREATION_COMPLETED,
  EVENTS.SHIPMENT_CREATION_FAILED,
  EVENTS.SHIPMENT_CREATION_SENT,
  EVENTS.SHIPMENT_SENT_PURCHASE,
  EVENTS.SHIPMENT_CREATION_STARTED,
  EVENTS.SHIPMENT_DESTINATION_INPUTED,
  EVENTS.SHIPMENT_DESTINATION_INPUT_STARTED,
  EVENTS.SHIPMENT_DESTINATION_BY_GOOGLE_INPUT_STARTED,
  EVENTS.SHIPMENT_DESTINATION_BY_GOOGLE_ADDRESS_SELECTED,
  EVENTS.SHIPMENT_DESTINATION_BY_GOOGLE_ALERT_PRESENTED,
  EVENTS.SHIPMENT_DESTINATION_BY_GOOGLE_ALERT_ANSWERED,
  EVENTS.SHIPMENT_DIMENSIONS_INPUTED,
  EVENTS.SHIPMENT_GOODSVALUE_INPUTED,
  EVENTS.SHIPMENT_ORIGIN_INPUTED,
  EVENTS.SHIPMENT_ORIGIN_BY_GOOGLE_INPUT_STARTED,
  EVENTS.SHIPMENT_ORIGIN_BY_GOOGLE_ADDRESS_SELECTED,
  EVENTS.SHIPMENT_ORIGIN_BY_GOOGLE_ALERT_PRESENTED,
  EVENTS.SHIPMENT_ORIGIN_BY_GOOGLE_ALERT_ANSWERED,
  EVENTS.SHIPMENT_PACKAGING_INPUTED,
  EVENTS.SHIPMENT_QUOTATION_PRESENTED,
  EVENTS.SHIPMENT_RECIPIENT_INPUTED,
  EVENTS.SHIPMENT_SERVICE_SELECTED,
  EVENTS.SHIPMENT_COUPON_INPUTED,
  EVENTS.USER_CREATION_INTENDED,
  EVENTS.USER_INTENTION_INFORMED,
  EVENTS.WALLET_CREDIT_ADDED
];

export const RUDDERSTACK_EVENTS = [
  EVENTS.ACCESS_COMPLETED,
  EVENTS.ACCESS_STARTED,
  EVENTS.ACCOUNT_CREATION_COMPLETED,
  EVENTS.ACCOUNT_CREATION_COMPLETED_PJ,
  EVENTS.ACCOUNT_CREATION_COMPLETED_ROAS,
  EVENTS.ACCOUNT_CREATION_COMPLETED_ROAS_PJ,
  EVENTS.ACCOUNT_CREATION_COMPLETED_PLUS_30,
  EVENTS.ACCOUNT_CREATION_STARTED,
  EVENTS.ACCOUNT_CREATION_SUBMITTED,
  EVENTS.ACCOUNT_DELETED,
  EVENTS.ACCOUNT_LOGGED_OUT,
  EVENTS.ACCOUNT_LOGIN_VIEWED,
  EVENTS.ACCOUNT_SHIPMENT_QUALIFICATION_INFORMED,
  EVENTS.ACCOUNT_VOLUME_QUALIFICATION_INFORMED,
  EVENTS.ACCOUNT_TYPE_INFORMED,
  EVENTS.ACCOUNT_PHONE_INFORMED,
  EVENTS.ACCOUNT_DOCUMENT_INFORMED,
  EVENTS.ACCOUNT_ZIPCODE_INFORMED,
  EVENTS.ACCOUNT_SEGMENT_INFORMED,
  EVENTS.ACCOUNT_INTEGRATORS_INFORMED,
  EVENTS.ACCOUNT_CREATION_FAILED,
  EVENTS.ACCOUNT_AVERAGE_TICKET_INFORMED,
  EVENTS.COUPON_BANNER_CHECKED,
  EVENTS.HOME_CORP_BUTTON_CLICKED,
  EVENTS.HOME_SERVICES_CHECKED,
  EVENTS.HOME_SERVICE_CLICKED,
  EVENTS.HOME_VISITED,
  EVENTS.MENU_OPENED,
  EVENTS.MENU_OPTION_SELECTED,
  EVENTS.PAGE_CHECKOUT_VIEWED,
  EVENTS.PAGE_DATA_CONFIRMATION_VIEWED,
  EVENTS.PAGE_DIMENSIONS_VIEWED,
  EVENTS.PAGE_GOODSVALUE_VIEWED,
  EVENTS.PAGE_PICKUP_SCHEDULE_VIEWED,
  EVENTS.PAGE_RECIPIENT_VIEWED,
  EVENTS.PAGE_SENDER_VIEWED,
  EVENTS.PICKUP_SCHEDULE_SLOT_SELECTED,
  EVENTS.PRESIGN_VISITED,
  EVENTS.SEND_USER,
  EVENTS.SHIPMENT_ADDRESS_BY_CEP_SELECTED,
  EVENTS.SHIPMENT_ADDRESS_BY_CEP_QUOTATION_SENT,
  EVENTS.SHIPMENT_ADDRESS_BY_CEP_QUOTATION_FAILED,
  EVENTS.SHIPMENT_ADDRESS_BY_CEP_ORIGIN_INFOS_INPUTED,
  EVENTS.SHIPMENT_ADDRESS_BY_CEP_ORIGIN_INFOS_FAILED,
  EVENTS.SHIPMENT_ADDRESS_BY_CEP_DESTINATION_INFOS_INPUTED,
  EVENTS.SHIPMENT_ADDRESS_BY_CEP_DESTINATION_INFOS_FAILED,
  EVENTS.SHIPMENT_ADDRESS_BY_CEP_CORP_ALERT_CLICKED,
  EVENTS.SHIPMENT_ADDRESS_BY_GOOGLE_SELECTED,
  EVENTS.SHIPMENT_CANCELLED,
  EVENTS.SHIPMENT_CREATION_COMPLETED,
  EVENTS.SHIPMENT_CREATION_FAILED,
  EVENTS.SHIPMENT_CREATION_SENT,
  EVENTS.SHIPMENT_SENT_PURCHASE,
  EVENTS.SHIPMENT_CREATION_STARTED,
  EVENTS.SHIPMENT_DESTINATION_INPUTED,
  EVENTS.SHIPMENT_DESTINATION_INPUT_STARTED,
  EVENTS.SHIPMENT_DESTINATION_BY_GOOGLE_INPUT_STARTED,
  EVENTS.SHIPMENT_DESTINATION_BY_GOOGLE_ADDRESS_SELECTED,
  EVENTS.SHIPMENT_DESTINATION_BY_GOOGLE_ALERT_PRESENTED,
  EVENTS.SHIPMENT_DESTINATION_BY_GOOGLE_ALERT_ANSWERED,
  EVENTS.SHIPMENT_DIMENSIONS_INPUTED,
  EVENTS.SHIPMENT_GOODSVALUE_INPUTED,
  EVENTS.SHIPMENT_ORIGIN_INPUTED,
  EVENTS.SHIPMENT_ORIGIN_BY_GOOGLE_INPUT_STARTED,
  EVENTS.SHIPMENT_ORIGIN_BY_GOOGLE_ADDRESS_SELECTED,
  EVENTS.SHIPMENT_ORIGIN_BY_GOOGLE_ALERT_PRESENTED,
  EVENTS.SHIPMENT_ORIGIN_BY_GOOGLE_ALERT_ANSWERED,
  EVENTS.SHIPMENT_PACKAGING_INPUTED,
  EVENTS.SHIPMENT_QUOTATION_PRESENTED,
  EVENTS.SHIPMENT_RECIPIENT_INPUTED,
  EVENTS.SHIPMENT_SERVICE_SELECTED,
  EVENTS.SHIPMENT_COUPON_INPUTED,
  EVENTS.USER_CREATION_INTENDED,
  EVENTS.USER_INTENTION_INFORMED,
  EVENTS.WALLET_CREDIT_ADDED
];

export const SINGULAR_EVENTS = [
  EVENTS.ACCOUNT_CREATION_COMPLETED,
  EVENTS.ACCOUNT_CREATION_COMPLETED_ROAS,
  EVENTS.SHIPMENT_CREATION_COMPLETED,
  EVENTS.SHIPMENT_QUOTATION_PRESENTED,
  EVENTS.SEND_USER
];

export const SINGULAR_REVENUE_EVENTS = [
  REVENUE_EVENTS.SHIPMENT_CREATION_COMPLETED
];

export const NEW_MONTHLY_PACKAGE_RANGE_VALUES = {
  DEFAULT: 0.5,
  MICRO: 4,
  SMALL: 30,
  MEDIUM: 100,
  LARGE: 500,
  EXTRA: 501
};

export const SERVICES = {
  EXPRESSO: 'expresso',
  LOGGI_FACIL: 'loggi_facil',
  CORREIOS: 'envio_pelos_correios',
  DROPOFF: 'loggi_ponto'
};

export const WALLET_CREDIT_ADDED_OPTIONS = {
  PIX: 'pix',
  CARTAO: 'cartao'
};

export const PACKAGING = {
  BY_LOGGI: 'embalado_pela_loggi',
  BY_CLIENT: 'embalado_pelo_cliente'
};

export const CREATION_METHOD = {
  EMAIL: 'cadastro_via_email',
  GOOGLE: 'cadastro_via_google',
  APPLE: 'cadastro_via_apple'
};

export const USER_TYPE = {
  PERSONAL_CUSTOMER: 'PersonalCustomer',
  CORPORATE_USER: 'CorporateUser',
  DRIVER: 'Driver'
};

export const APP_INFO = {
  PRODUCT: 'beyond',
  SERVICE: 'app',
  STACK: 'pwa'
};

export const ACCESS_METHOD = {
  EMAIL_LOGIN: 'login_via_email',
  EMAIL_SIGNUP: CREATION_METHOD.EMAIL,
  GOOGLE: 'accesso_via_google',
  APPLE: 'acesso_via_apple'
};

export const SHIPPING_QUALIFICATION_OPTION = {
  ENVIO_PRODUTOS_QUE_VENDO: 'envio_produtos_que_vendo',
  ENVIO_ITENS_PARA_CONHECIDOS: 'envio_itens_para_conhecidos',
  ENVIO_CORPORATIVO: 'envio_corporativo',
  QUERO_RASTREAR: 'quero_rastrear',
  SOU_ENTREGADOR: 'sou_entregador'
};

export const VOLUME_QUALIFICATION_OPTION = {
  ALGUNS_PCTS_POR_ANO: 'alguns_pcts_no_ano',
  ENTRE_1_A_4_PCTS_POR_MES: '1_a_4_pcts_no_mes',
  ENTRE_5_A_30_PCTS_POR_MES: '5_a_30_pcts_no_mes',
  ENTRE_31_A_100_PCTS_POR_MES: '31_a_100_pcts_no_mes',
  MAIS_100_PCTS_POR_MES: 'mais_100_pcts_no_mes'
};

export const NEW_VOLUME_QUALIFICATION_OPTION_STRING = {
  [NEW_MONTHLY_PACKAGE_RANGE_VALUES.DEFAULT]: 'alguns_pcts_no_ano',
  [NEW_MONTHLY_PACKAGE_RANGE_VALUES.MICRO]: '1_a_4_pcts_no_mes',
  [NEW_MONTHLY_PACKAGE_RANGE_VALUES.SMALL]: '5_a_30_pcts_no_mes',
  [NEW_MONTHLY_PACKAGE_RANGE_VALUES.MEDIUM]: '31_a_100_pcts_no_mes',
  [NEW_MONTHLY_PACKAGE_RANGE_VALUES.LARGE]: '101_a_500_pcts_no_mes',
  [NEW_MONTHLY_PACKAGE_RANGE_VALUES.EXTRA]: 'mais_500_pcts_no_mes'
};

export const BUTTON_OPTION = {
  EXPRESSO: 'expresso',
  MY_SHIPMENTS: 'my_shipments',
  TRACKING: 'tracking',
  DELETE_ACCOUNT: 'delete_account',
  NEW_SHIPMENT: 'new_shipment'
};

export const USER_INTENTION = {
  CREATE_SHIPMENT: 'criar_envio',
  TRACKING: 'rastrear'
};

export const ADDRESS_BY = {
  GOOGLE: 'address_by_google',
  CEP: 'address_by_cep'
};

export const ALERT_ANSWER = {
  WITH_NUMBER: 'incluir_numero',
  WITHOUT_NUMBER: 'continuar_sem_numero'
};

export const SHIPMENT_BY_GOOGLE_TYPE = {
  ALERT_ANSWERED: 'alert_answered',
  ALERT_PRESENTED: 'alert_presented',
  ADDRESS_SELECTED: 'address_selected',
  INPUT_STARTED: 'input_started'
};

export const SHIPMENT_ADDRESS_BY_CEP_PAGE = {
  ORIGIN: 'tela_endereco_origem',
  DESTINATION: 'tela_endereco_destino'
};

export const DEFAULT_NULL_NUMBER = -1;

export default EVENTS;

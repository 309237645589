import CRMBaseEvent from 'crm/entities/events/crm-base-event';
import EVENTS, { NEW_VOLUME_QUALIFICATION_OPTION_STRING } from 'crm/constants';
import {
  formatPhoneNumber,
  getCreationMethod,
  getFirstName,
  getLastName
} from 'crm/utils';

export default class AccountCreationCompletedPj extends CRMBaseEvent {
  constructor({
    companyId,
    name,
    email,
    origin,
    phone,
    cnpj,
    cpf,
    estimatedMonthlyPackages,
    cep,
    sharedName,
    userType,
    integrators,
    otherIntegrator,
    averageTicket,
    shipmentQualification,
    volumeQualification,
    provider,
    segmentName,
    phoneFromValidationBase
  }) {
    super();
    this.eventName = EVENTS.ACCOUNT_CREATION_COMPLETED_PJ;
    this.payload = {
      company_id: companyId ? String(companyId) : undefined,
      first_name: getFirstName(name),
      last_name: getLastName(name),
      email,
      origin,
      phone: formatPhoneNumber(phone),
      phone_from_validation_base: formatPhoneNumber(phoneFromValidationBase),
      document: cnpj || cpf,
      estimated_monthly_volume: estimatedMonthlyPackages,
      cep,
      shared_name: sharedName,
      user_type: userType,
      integrators,
      other_integrator: otherIntegrator,
      average_ticket: averageTicket,
      shipment_qualification: shipmentQualification,
      volume_qualification: volumeQualification,
      volume_qualification_string:
        NEW_VOLUME_QUALIFICATION_OPTION_STRING[volumeQualification],
      registration_method: getCreationMethod(provider),
      segment: segmentName
    };
  }
}
